import actions from './actions';

const {
  GET_DASHBOARDS_BEGIN,
  GET_DASHBOARDS_SUCCESS,
  GET_DASHBOARDS_ERROR,
  GET_WIDGET_DETAIL_BEGIN,
  GET_WIDGET_DETAIL_SUCCESS,
  GET_WIDGET_DETAIL_ERROR,
} = actions;

const initState = {
  dashboards: [],
  loading: false,
  error: null,
};

const dashboardsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_DASHBOARDS_BEGIN:
    case GET_WIDGET_DETAIL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_DASHBOARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboards: data,
      };
    case GET_WIDGET_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_DASHBOARDS_ERROR:
      return {
        ...state,
        dashboards: [],
        loading: false,
        error: err,
      };
    case GET_WIDGET_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
};

export { dashboardsReducer };
