import actions from './actions';

const {
  CREATE_INVOICE_BEGIN,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  PATCH_INVOICE_BEGIN,
  PATCH_INVOICE_SUCCESS,
  PATCH_INVOICE_ERROR,
  UPDATE_INVOICE_BEGIN,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_ERROR,
  GET_INVOICES_BEGIN,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  GET_ONE_INVOICE_BEGIN,
  GET_ONE_INVOICE_SUCCESS,
  GET_ONE_INVOICE_ERROR,
  DOWNLOAD_WORK_ORDER_BEGIN,
  DOWNLOAD_WORK_ORDER_SUCCESS,
  DOWNLOAD_WORK_ORDER_ERROR,
  DOWNLOAD_WORK_ORDER_BY_PDF_BEGIN,
  DOWNLOAD_WORK_ORDER_BY_PDF_SUCCESS,
  DOWNLOAD_WORK_ORDER_BY_PDF_ERROR,
  DOWNLOAD_DRAFT_WINSBBUP_BEGIN,
  DOWNLOAD_DRAFT_WINSBBUP_SUCCESS,
  DOWNLOAD_DRAFT_WINSBBUP_ERROR,
  UPDATE_WINSBBUP_INVOICE_BEGIN,
  UPDATE_WINSBBUP_INVOICE_SUCCESS,
  UPDATE_WINSBBUP_INVOICE_ERROR,
  DELETE_INVOICE_BEGIN,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_ERROR,
  CREATE_FILE_EVRIM_BEGIN,
  CREATE_FILE_EVRIM_SUCCESS,
  CREATE_FILE_EVRIM_ERROR,
  GET_SHIPPING_BEGIN,
  GET_SHIPPING_SUCCESS,
  GET_SHIPPING_ERROR,
  CREATE_SHIPPING_BEGIN,
  CREATE_SHIPPING_SUCCESS,
  CREATE_SHIPPING_ERROR,
  GET_SB_STOCK_BEGIN,
  GET_SB_STOCK_SUCCESS,
  GET_SB_STOCK_ERROR,
  CREATE_SB_STOCK_BEGIN,
  CREATE_SB_STOCK_SUCCESS,
  CREATE_SB_STOCK_ERROR,
  CREATE_REF_INVOICE_BEGIN,
  CREATE_REF_INVOICE_SUCCESS,
  CREATE_REF_INVOICE_ERROR,
  UPDATE_REF_INVOICE_BEGIN,
  UPDATE_REF_INVOICE_SUCCESS,
  UPDATE_REF_INVOICE_ERROR,
  CREATE_MANUEL_INVOICE_BEGIN,
  CREATE_MANUEL_INVOICE_SUCCESS,
  CREATE_MANUEL_INVOICE_ERROR,
  UPDATE_INVOICE_ITEM_BEGIN,
  UPDATE_INVOICE_ITEM_SUCCESS,
  UPDATE_INVOICE_ITEM_ERROR,
  GET_INVOICE_ITEM_BEGIN,
  GET_INVOICE_ITEM_SUCCESS,
  GET_INVOICE_ITEM_ERROR,
  CHECK_INVOICES_BEGIN,
  CHECK_INVOICES_SUCCESS,
  CHECK_INVOICES_ERROR,
  PATCH_INVOICE_ZONE_BEGIN,
  PATCH_INVOICE_ZONE_SUCCESS,
  PATCH_INVOICE_ZONE_ERROR,
  DOWNLOAD_UBF_DRAFT_BEGIN,
  DOWNLOAD_UBF_DRAFT_SUCCESS,
  DOWNLOAD_UBF_DRAFT_ERROR,
  CHECK_EVRIM_REF_BEGIN,
  CHECK_EVRIM_REF_SUCCESS,
  CHECK_EVRIM_REF_ERROR,
  RESET_ERROR_MESSAGE,
} = actions;

const initState = {
  invoices: [],
  shipping: [],
  stock: [],
  loading: false,
  error: null,
};

const sbReducer = (state = initState, action) => {
  const { type, data, page, err, id } = action;
  switch (type) {
    case CREATE_INVOICE_BEGIN:
    case PATCH_INVOICE_BEGIN:
    case GET_INVOICES_BEGIN:
    case DELETE_INVOICE_BEGIN:
    case GET_ONE_INVOICE_BEGIN:
    case UPDATE_INVOICE_BEGIN:
    case DOWNLOAD_WORK_ORDER_BEGIN:
    case DOWNLOAD_WORK_ORDER_BY_PDF_BEGIN:
    case DOWNLOAD_DRAFT_WINSBBUP_BEGIN:
    case UPDATE_WINSBBUP_INVOICE_BEGIN:
    case CREATE_FILE_EVRIM_BEGIN:
    case GET_SHIPPING_BEGIN:
    case CREATE_SHIPPING_BEGIN:
    case GET_SB_STOCK_BEGIN:
    case CREATE_SB_STOCK_BEGIN:
    case CREATE_REF_INVOICE_BEGIN:
    case UPDATE_REF_INVOICE_BEGIN:
    case CREATE_MANUEL_INVOICE_BEGIN:
    case UPDATE_INVOICE_ITEM_BEGIN:
    case GET_INVOICE_ITEM_BEGIN:
    case CHECK_INVOICES_BEGIN:
    case PATCH_INVOICE_ZONE_BEGIN:
    case DOWNLOAD_UBF_DRAFT_BEGIN:
    case CHECK_EVRIM_REF_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_INVOICE_SUCCESS:
      const newInvoices = [...data, ...state.invoices.list];
      return {
        ...state,
        invoices: {
          list: newInvoices,
          count: newInvoices.length,
        },
        loading: false,
      };
    case PATCH_INVOICE_SUCCESS:
      const invoiceItemIndex = state.invoices.list.findIndex(
        s => s.id === data[0].id
      );
      state.invoices.list[invoiceItemIndex] = data[0];
      return {
        ...state,
        loading: false,
        error: null,
        invoices: {
          list: state.invoices.list,
          count: state.invoices.count,
        },
      };
    case CREATE_MANUEL_INVOICE_SUCCESS:
      return { ...state, loading: false };
    case CREATE_SHIPPING_SUCCESS:
      const newShippingList = [...state.shipping, data];
      return {
        ...state,
        loading: false,
        shipping: newShippingList,
      };
    case CREATE_SB_STOCK_SUCCESS:
      const newStockList = [...state.stock, data];
      return {
        ...state,
        loading: false,
        stock: newStockList,
      };
    case GET_INVOICES_SUCCESS:
      if (page > 1) {
        return {
          ...state,
          loading: false,
          invoices: {
            list: [...state.invoices.list, ...data.list],
            count: data.count,
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          invoices: data,
        };
      }
    case GET_SHIPPING_SUCCESS:
      return {
        ...state,
        shipping: data,
        loading: false,
      };
    case GET_SB_STOCK_SUCCESS:
      return {
        ...state,
        stock: data,
        loading: false,
      };
    case DELETE_INVOICE_SUCCESS:
      const invoiceIndex = state.invoices.list.findIndex(
        item => item.id === id
      );
      state.invoices.list.splice(invoiceIndex, 1);
      state.invoices.count -= 1;
      return {
        ...state,
        loading: false,
        invoices: state.invoices,
      };
    case GET_ONE_INVOICE_SUCCESS:
    case UPDATE_INVOICE_SUCCESS:
    case DOWNLOAD_WORK_ORDER_SUCCESS:
    case DOWNLOAD_WORK_ORDER_BY_PDF_SUCCESS:
    case DOWNLOAD_DRAFT_WINSBBUP_SUCCESS:
    case UPDATE_WINSBBUP_INVOICE_SUCCESS:
    case CREATE_FILE_EVRIM_SUCCESS:
    case CREATE_REF_INVOICE_SUCCESS:
    case UPDATE_REF_INVOICE_SUCCESS:
    case UPDATE_INVOICE_ITEM_SUCCESS:
    case GET_INVOICE_ITEM_SUCCESS:
    case CHECK_INVOICES_SUCCESS:
    case PATCH_INVOICE_ZONE_SUCCESS:
    case DOWNLOAD_UBF_DRAFT_SUCCESS:
    case CHECK_EVRIM_REF_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_INVOICE_ERROR:
    case PATCH_INVOICE_ERROR:
    case GET_INVOICES_ERROR:
    case DELETE_INVOICE_ERROR:
    case GET_ONE_INVOICE_ERROR:
    case UPDATE_INVOICE_ERROR:
    case DOWNLOAD_DRAFT_WINSBBUP_ERROR:
    case DOWNLOAD_WORK_ORDER_ERROR:
    case DOWNLOAD_WORK_ORDER_BY_PDF_ERROR:
    case UPDATE_WINSBBUP_INVOICE_ERROR:
    case CREATE_FILE_EVRIM_ERROR:
    case GET_SHIPPING_ERROR:
    case CREATE_SHIPPING_ERROR:
    case GET_SB_STOCK_ERROR:
    case CREATE_SB_STOCK_ERROR:
    case CREATE_REF_INVOICE_ERROR:
    case UPDATE_REF_INVOICE_ERROR:
    case CREATE_MANUEL_INVOICE_ERROR:
    case UPDATE_INVOICE_ITEM_ERROR:
    case GET_INVOICE_ITEM_ERROR:
    case CHECK_INVOICES_ERROR:
    case PATCH_INVOICE_ZONE_ERROR:
    case DOWNLOAD_UBF_DRAFT_ERROR:
    case CHECK_EVRIM_REF_ERROR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case RESET_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export { sbReducer };
