import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  soList,
  GitReport,
  GitMoves,
  GitInvoices,
  GitMovesDetail,
  GitInvoicesDetail,
  ProductDeclaration,
  ShipmentDeclarationDetail,
  ProductDeclarationDetail,
  ShipmentDeclarationList,
  ProductList,
  ProductDetail,
  ConsignmentNote,
  AntrepoBeyannameler,
  AntrepoStokRaporu,
  IthalatBeyannameleri,
  AntrepoStokTalep,
  AntrepoStokTalepListesi,
  AntrepoStokTalepYukle,
  PaymentDeclaration,
  BeyannameDetaySorgula,
  BeyannameSorgula,
  GenelRaporlar,
  GenelRaporlarDetaylar,
  GtipTanimlari,
  GtipDetaylari,
  SoBeyannameOnizleme,
  EskiSolar,
  PlannedJobList,
  AddPlanedJob,
  ContainerTracking,
  DemoExcel,
  AddProduct,
  DocumentTracking,
  DocumentTrackingDetail,
  DocumentTrackingNew,
  Order,
  OrderList,
  GeciciIthalat,
  IhbarTespit,
  OrderMasraf,
  ProductTariffList,
  ProductTariffUpdateScreen,
  IthalatBeyannamesi,
  IthalatListe,
  OrderMasrafDekont,
} from './index';

const antrepoStok = {
  soList: soList,
  gitRaporuYukleme: GitReport,
  gitHareketleri: GitMoves,
  gitFaturalar: GitInvoices,
  gitHareketDetaylari: GitMovesDetail,
  gitFaturalarDetaylar: GitInvoicesDetail,
  urunBildirim: ProductDeclaration,
  sevkiyatBildirimi: ShipmentDeclarationList,
  sevkiyatBildirimDetaylar: ShipmentDeclarationDetail,
  urunBildirimDetaylari: ProductDeclarationDetail,
  urunler: ProductList,
  odeme: PaymentDeclaration,
  urunDetaylari: ProductDetail,
  konsimento: ConsignmentNote,
  antrepoBeyannameler: AntrepoBeyannameler,
  antrepoRaporu: AntrepoStokRaporu,
  ithalatBeyannameleri: IthalatBeyannameleri,
  antrepoStokTalep: AntrepoStokTalep,
  antrepoStokTalepListesi: AntrepoStokTalepListesi,
  antrepoStokTalepYukle: AntrepoStokTalepYukle,
  beyannameDetaySorgula: BeyannameDetaySorgula,
  beyannameSorgula: BeyannameSorgula,
  genelRaporlar: GenelRaporlar,
  genelRaporlarDetaylar: GenelRaporlarDetaylar,
  gtipTanimlari: GtipTanimlari,
  gtipTanimlariDetaylari: GtipDetaylari,
  soBeyannameOnizleme: SoBeyannameOnizleme,
  eskiSolar: EskiSolar,
  sureliIslemlerListesi: PlannedJobList,
  sureliIslemEkle: AddPlanedJob,
  demoKonteynerSorgulama: ContainerTracking,
  demoExcel: DemoExcel,
  urunEkle: AddProduct,
  documentTracking: DocumentTracking,
  documentTrackingDetail: DocumentTrackingDetail,
  documentTrackingNew: DocumentTrackingNew,
  order: Order,
  orderList: OrderList,
  geciciIthalat: GeciciIthalat,
  ihbarTespit: IhbarTespit,
  orderMasraf: OrderMasraf,
  urunTarife: ProductTariffList,
  urunTarifeEkle: ProductTariffUpdateScreen, // Ekleme Ve Güncelleme Aynı Ekran Çalışmakta
  urunTarifeDuzenle: ProductTariffUpdateScreen,
  beyanname: IthalatBeyannamesi,
  beyannameListe: IthalatListe,
  orderMasrafDekont: OrderMasrafDekont,
};

const AntrepoStokRoutes = ({ menu }) => {
  return (
    <Switch>
      {/** Burada children menüye göre düzenleme yapılacak  */}
      {/** Menüde görünen routeları render etmektedir.  */}
      {menu.map(({ id, link, key }) => {
        return (
          <Route key={id} path={link} component={antrepoStok[key]} exact />
        );
      })}
      {/**  Child Routeları render etmektedir.Menude listelenmemektedir. */}
      {menu.map(({ childRoute }) => {
        return (
          childRoute.length !== 0 &&
          childRoute.map(({ id, link, key }) => {
            return (
              <Route key={id} path={link} component={antrepoStok[key]} exact />
            );
          })
        );
      })}
    </Switch>
  );
};

export default AntrepoStokRoutes;
