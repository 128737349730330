const actions = {
  UPLOAD_GIT_BEGIN: 'UPLOAD_GIT_BEGIN',
  UPLOAD_GIT_SUCCESS: 'UPLOAD_GIT_SUCCESS',
  UPLOAD_GIT_ERROR: 'UPLOAD_GIT_ERROR',

  ADD_GIT_BEGIN: 'ADD_GIT_BEGIN',
  ADD_GIT_SUCCESS: 'ADD_GIT_SUCCESS',
  ADD_GIT_ERROR: 'ADD_GIT_ERROR',

  GET_GIT_INVOICES_BEGIN: 'GET_GIT_INVOICES_BEGIN',
  GET_GIT_INVOICES_SUCCESS: 'GET_GIT_INVOICES_SUCCESS',
  GET_GIT_INVOICES_ERROR: 'GET_GIT_INVOICES_ERROR',

  ADD_GIT_CONSIGNMENTNOTE_BEGIN: 'ADD_GIT_CONSIGNMENTNOTE_BEGIN',
  ADD_GIT_CONSIGNMENTNOTE_SUCCESS: 'ADD_GIT_CONSIGNMENTNOTE_SUCCESS',
  ADD_GIT_CONSIGNMENTNOTE_ERROR: 'ADD_GIT_CONSIGNMENTNOTE_ERROR',

  UPDATE_GIT_CONSIGNMENTNOTE_BEGIN: 'UPDATE_GIT_CONSIGNMENTNOTE_BEGIN',
  UPDATE_GIT_CONSIGNMENTNOTE_SUCCESS: 'UPDATE_GIT_CONSIGNMENTNOTE_SUCCESS',
  UPDATE_GIT_CONSIGNMENTNOTE_ERROR: 'UPDATE_GIT_CONSIGNMENTNOTE_ERROR',

  ADD_PAYMENT_DECLARATION_BEGIN: 'ADD_PAYMENT_DECLARATION_BEGIN',
  ADD_PAYMENT_DECLARATION_SUCCESS: 'ADD_PAYMENT_DECLARATION_SUCCESS',
  ADD_PAYMENT_DECLARATION_ERROR: 'ADD_PAYMENT_DECLARATION_ERROR',

  UPDATE_PAYMENT_DECLARATION_BEGIN: 'UPDATE_PAYMENT_DECLARATION_BEGIN',
  UPDATE_PAYMENT_DECLARATION_SUCCESS: 'UPDATE_PAYMENT_DECLARATION_SUCCESS',
  UPDATE_PAYMENT_DECLARATION_ERROR: 'UPDATE_PAYMENT_DECLARATION_ERROR',

  UPDATE_INVOICES_ETA_BEGIN: 'UPDATE_INVOICES_ETA_BEGIN',
  UPDATE_INVOICES_ETA_SUCCESS: 'UPDATE_INVOICES_ETA_SUCCESS',
  UPDATE_INVOICES_ETA_ERROR: 'UPDATE_INVOICES_ETA_BEGIN',

  GET_SO_LIST_BEGIN: 'GET_SO_LIST_BEGIN',
  GET_SO_LIST_SUCCESS: 'GET_SO_LIST_SUCCESS',
  GET_SO_LIST_ERROR: 'GET_SO_LIST_ERROR',

  ADD_SO_LIST_BEGIN: 'ADD_SO_LIST_BEGIN',
  ADD_SO_LIST_SUCCESS: 'ADD_SO_LIST_SUCCESS',
  ADD_SO_LIST_ERROR: 'ADD_SO_LIST_ERROR',

  PATCH_SO_LIST_BEGIN: 'PATCH_SO_LIST_BEGIN',
  PATCH_SO_LIST_SUCCESS: 'PATCH_SO_LIST_SUCCESS',
  PATCH_SO_LIST_ERROR: 'PATCH_SO_LIST_ERROR',

  GET_SO_PRODUCT_BEGIN: 'GET_SO_PRODUCT_BEGIN',
  GET_SO_PRODUCT_SUCCESS: 'GET_SO_PRODUCT_SUCCESS',
  GET_SO_PRODUCT_ERROR: 'GET_SO_PRODUCT_ERROR',

  DOWNLOAD_DOCUMENT_BEGIN: 'DOWNLOAD_DOCUMENT_BEGIN',
  DOWNLOAD_DOCUMENT_SUCCESS: 'DOWNLOAD_DOCUMENT_SUCCESS',
  DOWNLOAD_DOCUMENT_ERROR: 'DOWNLOAD_DOCUMENT_ERROR',

  CREATE_DOCUMENT_RELATION_BEGIN: 'CREATE_DOCUMENT_RELATION_BEGIN',
  CREATE_DOCUMENT_RELATION_SUCCESS: 'CREATE_DOCUMENT_RELATION_SUCCESS',
  CREATE_DOCUMENT_RELATION_ERROR: 'CREATE_DOCUMENT_RELATION_ERROR',

  GET_DOCUMENT_RELATION_BEGIN: 'GET_DOCUMENT_RELATION_BEGIN',
  GET_DOCUMENT_RELATION_SUCCESS: 'GET_DOCUMENT_RELATION_SUCCESS',
  GET_DOCUMENT_RELATION_ERROR: 'GET_DOCUMENT_RELATION_ERROR',

  DELETE_DOCUMENT_RELATION_BEGIN: 'DELETE_DOCUMENT_RELATION_BEGIN',
  DELETE_DOCUMENT_RELATION_SUCCESS: 'DELETE_DOCUMENT_RELATION_SUCCESS',
  DELETE_DOCUMENT_RELATION_ERROR: 'DELETE_DOCUMENT_RELATION_ERROR',

  GET_CURRENCY_BEGIN: 'GET_CURRENCY_BEGIN',
  GET_CURRENCY_SUCCESS: 'GET_CURRENCY_SUCCESS',
  GET_CURRENCY_ERROR: 'GET_CURRENCY_ERROR',

  GET_ORIGIN_BEGIN: 'GET_ORIGIN_BEGIN',
  GET_ORIGIN_SUCCESS: 'GET_ORIGIN_SUCCESS',
  GET_ORIGIN_ERROR: 'GET_ORIGIN_ERROR',

  GET_GTIP_DOCUMENT_TYPE_BEGIN: 'GET_GTIP_DOCUMENT_TYPE_BEGIN',
  GET_GTIP_DOCUMENT_TYPE_SUCCESS: 'GET_GTIP_DOCUMENT_TYPE_SUCCESS',
  GET_GTIP_DOCUMENT_TYPE_ERROR: 'GET_GTIP_DOCUMENT_TYPE_ERROR',

  GET_PRODUCT_DOCUMENT_CONTROL_BEGIN: 'GET_PRODUCT_DOCUMENT_CONTROL_BEGIN',
  GET_PRODUCT_DOCUMENT_CONTROL_SUCCESS: 'GET_PRODUCT_DOCUMENT_CONTROL_SUCCESS',
  GET_PRODUCT_DOCUMENT_CONTROL_ERROR: 'GET_PRODUCT_DOCUMENT_CONTROL_ERROR',

  ADD_OR_UPDATE_PRODUCT_DOCUMENT_CONTROL_BEGIN:
    'ADD_OR_UPDATE_PRODUCT_DOCUMENT_CONTROL_BEGIN',
  ADD_OR_UPDATE_PRODUCT_DOCUMENT_CONTROL_SUCCESS:
    'ADD_OR_UPDATE_PRODUCT_DOCUMENT_CONTROL_SUCCESS',
  ADD_OR_UPDATE_PRODUCT_DOCUMENT_CONTROL_ERROR:
    'ADD_OR_UPDATE_PRODUCT_DOCUMENT_CONTROL_ERROR',

  ADD_OR_UPDATE_GTIP_DOCUMENT_TYPE_BEGIN:
    'ADD_OR_UPDATE_GTIP_DOCUMENT_TYPE_BEGIN',
  ADD_OR_UPDATE_GTIP_DOCUMENT_TYPE_SUCCESS:
    'ADD_OR_UPDATE_GTIP_DOCUMENT_TYPE_SUCCESS',
  ADD_OR_UPDATE_GTIP_DOCUMENT_TYPE_ERROR:
    'ADD_OR_UPDATE_GTIP_DOCUMENT_TYPE_ERROR',

  GET_PLANT_BEGIN: 'GET_PLANT_BEGIN',
  GET_PLANT_SUCCESS: 'GET_PLANT_SUCCESS',
  GET_PLANT_ERROR: 'GET_PLANT_ERROR',

  GET_PRODUCT_CATEGORY_BEGIN: 'GET_PRODUCT_CATEGORY_BEGIN',
  GET_PRODUCT_CATEGORY_SUCCESS: 'GET_PRODUCT_CATEGORY_SUCCESS',
  GET_PRODUCT_CATEGORY_ERROR: 'GET_PRODUCT_CATEGORY_ERROR',

  GET_MEASUREMENT_UNIT_BEGIN: 'GET_MEASUREMENT_UNIT_BEGIN',
  GET_MEASUREMENT_UNIT_SUCCESS: 'GET_MEASUREMENT_UNIT_SUCCESS',
  GET_MEASUREMENT_UNIT_ERROR: 'GET_MEASUREMENT_UNIT_ERROR',

  GET_DELIVERY_BEGIN: 'GET_DELIVERY_BEGIN',
  GET_DELIVERY_SUCCESS: 'GET_DELIVERY_SUCCESS',
  GET_DELIVERY_ERROR: 'GET_DELIVERY_ERROR',

  GET_TRANSPORT_BEGIN: 'GET_TRANSPORT_BEGIN',
  GET_TRANSPORT_SUCCESS: 'GET_TRANSPORT_SUCCESS',
  GET_TRANSPORT_ERROR: 'GET_TRANSPORT_ERROR',

  UPDATE_INVOICES_DETAIL_BEGIN: 'UPDATE_INVOICES_DETAIL_BEGIN',
  UPDATE_INVOICES_DETAIL_SUCCESS: 'UPDATE_INVOICES_DETAIL_SUCCESS',
  UPDATE_INVOICES_DETAIL_ERROR: 'UPDATE_INVOICES_DETAIL_ERROR',

  GET_WAREHOUSE_STOCK_BEGIN: 'GET_WAREHOUSE_STOCK_BEGIN',
  GET_WAREHOUSE_STOCK_SUCCESS: 'GET_WAREHOUSE_STOCK_SUCCESS',
  GET_WAREHOUSE_STOCK_ERROR: 'GET_WAREHOUSE_STOCK_ERROR',

  CREATE_DEMAND_BEGIN: 'CREATE_DEMAND_BEGIN',
  CREATE_DEMAND_SUCCESS: 'CREATE_DEMAND_SUCCESS',
  CREATE_DEMAND_ERROR: 'CREATE_DEMAND_ERROR',

  DELETE_DEMAND_BEGIN: 'DELETE_DEMAND_BEGIN',
  DELETE_DEMAND_SUCCESS: 'DELETE_DEMAND_SUCCESS',
  DELETE_DEMAND_ERROR: 'DELETE_DEMAND_ERROR',

  GET_DEMAND_BEGIN: 'GET_DEMAND_BEGIN',
  GET_DEMAND_SUCCESS: 'GET_DEMAND_SUCCESS',
  GET_DEMAND_ERROR: 'GET_DEMAND_ERROR',

  UPDATE_DEMAND_EVRIM_BEGIN: 'UPDATE_DEMAND_EVRIM_BEGIN',
  UPDATE_DEMAND_EVRIM_SUCCESS: 'UPDATE_DEMAND_EVRIM_SUCCESS',
  UPDATE_DEMAND_EVRIM_ERROR: 'UPDATE_DEMAND_EVRIM_ERROR',

  GET_DEMAND_INFO_BEGIN: 'GET_DEMAND_INFO_BEGIN',
  GET_DEMAND_INFO_SUCCESS: 'GET_DEMAND_INFO_SUCCESS',
  GET_DEMAND_INFO_ERROR: 'GET_DEMAND_INFO_ERROR',

  UPLOAD_DEMAND_BEGIN: 'UPLOAD_DEMAND_BEGIN',
  UPLOAD_DEMAND_SUCCESS: 'UPLOAD_DEMAND_SUCCESS',
  UPLOAD_DEMAND_ERROR: 'UPLOAD_DEMAND_ERROR',

  CREATE_DEMAND_EXCEL_BEGIN: 'CREATE_DEMAND_EXCEL_BEGIN',
  CREATE_DEMAND_EXCEL_SUCCESS: 'CREATE_DEMAND_EXCEL_SUCCESS',
  CREATE_DEMAND_EXCEL_ERROR: 'CREATE_DEMAND_EXCEL_ERROR',

  GET_ANTREPO_BEYANNAME_BEGIN: 'GET_ANTREPO_BEYANNAME_BEGIN',
  GET_ANTREPO_BEYANNAME_SUCCESS: 'GET_ANTREPO_BEYANNAME_SUCCESS',
  GET_ANTREPO_BEYANNAME_ERROR: 'GET_ANTREPO_BEYANNAME_ERROR',

  GET_ALL_SHIPMENT_DECLARATIONS_BEGIN: 'GET_ALL_SHIPMENT_DECLARATIONS_BEGIN',
  GET_ALL_SHIPMENT_DECLARATIONS_SUCCESS:
    'GET_ALL_SHIPMENT_DECLARATIONS_SUCCESS',
  GET_ALL_SHIPMENT_DECLARATIONS_ERROR: 'GET_ALL_SHIPMENT_DECLARATIONS_ERROR',

  GET_ITHALAT_BEYANNAME_BEGIN: 'GET_ITHALAT_BEYANNAME_BEGIN',
  GET_ITHALAT_BEYANNAME_SUCCESS: 'GET_ITHALAT_BEYANNAME_SUCCESS',
  GET_ITHALAT_BEYANNAME_ERROR: 'GET_ITHALAT_BEYANNAME_ERROR',

  GET_ANTREPO_STOK_BEGIN: 'GET_ANTREPO_STOK_BEGIN',
  GET_ANTREPO_STOK_SUCCESS: 'GET_ANTREPO_STOK_SUCCESS',
  GET_ANTREPO_STOK_ERROR: 'GET_ANTREPO_STOK_ERROR',

  GET_BEYANNAME_IS_TAKIP_DETAY_BEGIN: 'GET_BEYANNAME_IS_TAKIP_DETAY_BEGIN',
  GET_BEYANNAME_IS_TAKIP_DETAY_SUCCESS: 'GET_BEYANNAME_IS_TAKIP_DETAY_SUCCESS',
  GET_BEYANNAME_IS_TAKIP_DETAY_ERROR: 'GET_BEYANNAME_IS_TAKIP_DETAY_ERROR',

  GET_BEYANNAME_DETAY_SORGULA_BEGIN: 'GET_BEYANNAME_DETAY_SORGULA_BEGIN',
  GET_BEYANNAME_DETAY_SORGULA_SUCCESS: 'GET_BEYANNAME_DETAY_SORGULA_SUCCESS',
  GET_BEYANNAME_DETAY_SORGULA_ERROR: 'GET_BEYANNAME_DETAY_SORGULA_ERROR',

  PATCH_SO_NO_BEGIN: 'PATCH_SO_NO_BEGIN',
  PATCH_SO_NO_SUCCESS: 'PATCH_SO_NO_SUCCESS',
  PATCH_SO_NO_ERROR: 'PATCH_SO_NO_ERROR',

  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE', // Reduxdan Hata Temizlemektedir.

  GET_GIT_DATA: 'GET_GIT_DATA',
  GET_ALL_DATA_ERROR: 'GET_ALL_DATA_ERROR',

  GET_GIT_ONE_DOCUMENT_DATA: 'GET_GIT_ONE_DOCUMENT_DATA',

  DOWNLOAD_GİT_REPORT: 'DOWNLOAD_GİT_REPORT',
  DOWNLOAD_GİT_REPORT_SUCCESS: 'DOWNLOAD_GİT_REPORT_SUCCESS',
  DOWNLOAD_GİT_REPORT_FAİLED: 'DOWNLOAD_GİT_REPORT_FAİLED',

  GET_INVOICES_DATA: 'GET_INVOICES_DATA',
  GET_INVOICES_DATA_ERROR: 'GET_INVOICES_DATA_ERROR',

  DELETE_DOCUMENT_BEGIN: 'DELETE_DOCUMENT_BEGIN', //Daha sonradan sayfada redux kullanınca bu metodları kullanacaz.Şimdiden yazdım kalsın.
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_ERROR: 'DELETE_DOCUMENT_ERROR',

  GET_COUNTRIES_BEGIN: 'GET_COUNTRIES_BEGIN',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_ERROR: 'GET_COUNTRIES_ERROR',

  GET_WEBGUMRUK_BEYANNAME_BEGIN: 'GET_WEBGUMRUK_BEYANNAME_BEGIN',
  GET_WEBGUMRUK_BEYANNAME_SUCCESS: 'GET_WEBGUMRUK_BEYANNAME_SUCCESS',
  GET_WEBGUMRUK_BEYANNAME_ERROR: 'GET_WEBGUMRUK_BEYANNAME_ERROR',

  GET_PAYMENT_BEGIN: 'GET_PAYMENT_BEGIN',
  GET_PAYMENT_SUCCESS: 'GET_PAYMENT_SUCCESS',
  GET_PAYMENT_ERROR: 'GET_PAYMENT_ERROR',

  GET_EVRIM_ARCHIVE_BEGIN: 'GET_EVRIM_ARCHIVE_BEGIN',
  GET_EVRIM_ARCHIVE_SUCCESS: 'GET_EVRIM_ARCHIVE_SUCCESS',
  GET_EVRIM_ARCHIVE_ERROR: 'GET_EVRIM_ARCHIVE_ERROR',

  GET_EVRIM_ARCHIVE_DOCUMENT_BEGIN: 'GET_EVRIM_ARCHIVE_DOCUMENT_BEGIN',
  GET_EVRIM_ARCHIVE_DOCUMENT_SUCCESS: 'GET_EVRIM_ARCHIVE_DOCUMENT_SUCCESS',
  GET_EVRIM_ARCHIVE_DOCUMENT_ERROR: 'GET_EVRIM_ARCHIVE_DOCUMENT_ERROR',

  GET_GENEL_RAPORLAR_BEGIN: 'GET_GENEL_RAPORLAR_BEGIN',
  GET_GENEL_RAPORLAR_SUCCESS: 'GET_GENEL_RAPORLAR_SUCCESS',
  GET_GENEL_RAPORLAR_ERROR: 'GET_GENEL_RAPORLAR_ERROR',

  GET_GENEL_RAPORLAR_DETAY_BEGIN: 'GET_GENEL_RAPORLAR_DETAY_BEGIN',
  GET_GENEL_RAPORLAR_DETAY_SUCCESS: 'GET_GENEL_RAPORLAR_DETAY_SUCCESS',
  GET_GENEL_RAPORLAR_DETAY_ERROR: 'GET_GENEL_RAPORLAR_DETAY_ERROR',

  GET_GENEL_RAPOR_DETAYI_BEGIN: 'GET_GENEL_RAPOR_DETAYI_BEGIN',
  GET_GENEL_RAPOR_DETAYI_SUCCESS: 'GET_GENEL_RAPOR_DETAYI_SUCCESS',
  GET_GENEL_RAPOR_DETAYI_ERROR: 'GET_GENEL_RAPOR_DETAYI_ERROR',

  GET_COMPANY_BEGIN: 'GET_COMPANY_BEGIN',
  GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
  GET_COMPANY_ERROR: 'GET_COMPANY_ERROR',

  GET_WAREHOUSE_DEFINITIONS_BEGIN: 'GET_WAREHOUSE_DEFINITIONS_BEGIN',
  GET_WAREHOUSE_DEFINITIONS_SUCCESS: 'GET_WAREHOUSE_DEFINITIONS_SUCCESS',
  GET_WAREHOUSE_DEFINITIONS_ERROR: 'GET_WAREHOUSE_DEFINITIONS_ERROR',

  UPDATE_WAREHOUSE_DEFINITIONS_BEGIN: 'UPDATE_WAREHOUSE_DEFINITIONS_BEGIN',
  UPDATE_WAREHOUSE_DEFINITIONS_SUCCESS: 'UPDATE_WAREHOUSE_DEFINITIONS_SUCCESS',
  UPDATE_WAREHOUSE_DEFINITIONS_ERROR: 'UPDATE_WAREHOUSE_DEFINITIONS_ERROR',

  GET_AGENT_DEFINITIONS_BEGIN: 'GET_AGENT_DEFINITIONS_BEGIN',
  GET_AGENT_DEFINITIONS_SUCCESS: 'GET_AGENT_DEFINITIONS_SUCCESS',
  GET_AGENT_DEFINITIONS_ERROR: 'GET_AGENT_DEFINITIONS_ERROR',

  UPDATE_AGENT_DEFINITIONS_BEGIN: 'UPDATE_AGENT_DEFINITIONS_BEGIN',
  UPDATE_AGENT_DEFINITIONS_SUCCESS: 'UPDATE_AGENT_DEFINITIONS_SUCCESS',
  UPDATE_AGENT_DEFINITIONS_ERROR: 'UPDATE_AGENT_DEFINITIONS_ERROR',

  GET_PRODUCT_BEGIN: 'GET_PRODUCT_BEGIN',
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_ERROR: 'GET_PRODUCT_ERROR',

  GET_PRODUCT_ALL_BEGIN: 'GET_PRODUCT_ALL_BEGIN',
  GET_PRODUCT_ALL_SUCCESS: 'GET_PRODUCT_ALL_SUCCESS',
  GET_PRODUCT_ALL_ERROR: 'GET_PRODUCT_ALL_ERROR',

  UPDATE_COMPANY_BEGIN: 'UPDATE_COMPANY_BEGIN',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_ERROR: 'UPDATE_COMPANY_ERROR',

  GET_TALEP_LISTESI_BEGIN: 'GET_TALEP_LISTESI_BEGIN',
  GET_TALEP_LISTESI_SUCCESS: 'GET_TALEP_LISTESI_SUCCESS',
  GET_TALEP_LISTESI_ERROR: 'GET_TALEP_LISTESI_ERROR',

  DELETE_TALEP_BEGIN: 'DELETE_TALEP_SIL_BEGIN',
  DELETE_TALEP_SUCCESS: 'DELETE_TALEP_SIL_SUCCESS',
  DELETE_TALEP_ERROR: 'DELETE_TALEP_SIL_ERROR',

  GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_BEGIN:
    'GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_BEGIN',
  GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_SUCCESS:
    'GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_SUCCESS',
  GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_ERROR:
    'GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_ERROR',

  GET_GTIP_LIST_BEGIN: 'GET_GTIP_LIST_BEGIN',
  GET_GTIP_LIST_SUCCESS: 'GET_GTIP_LIST_SUCCESS',
  GET_GTIP_LIST_ERROR: 'GET_GTIP_LIST_ERROR',

  ADD_GTIP_BEGIN: 'ADD_GTIP_BEGIN',
  ADD_GTIP_SUCCESS: 'ADD_GTIP_SUCCESS',
  ADD_GTIP_ERROR: 'ADD_GTIP_ERROR',

  GET_GTIP_DETAY_BEGIN: 'GET_GTIP_DETAY_BEGIN',
  GET_GTIP_DETAY_SUCCESS: 'GET_GTIP_DETAY_SUCCESS',
  GET_GTIP_DETAY_ERROR: 'GET_GTIP_DETAY_ERROR',

  ADD_GTIP_DETAY_BEGIN: 'ADD_GTIP_DETAY_BEGIN',
  ADD_GTIP_DETAY_SUCCESS: 'ADD_GTIP_DETAY_SUCCESS',
  ADD_GTIP_DETAY_ERROR: 'ADD_GTIP_DETAY_ERROR',

  GET_BEYAN_ONIZLEME_BEGIN: 'GET_BEYAN_ONIZLEME_BEGIN',
  GET_BEYAN_ONIZLEME_SUCCESS: 'GET_BEYAN_ONIZLEME_SUCCESS',
  GET_BEYAN_ONIZLEME_ERROR: 'GET_BEYAN_ONIZLEME_ERROR',

  GET_OLD_SO_LIST_BEGIN: 'GET_OLD_SO_LIST_BEGIN',
  GET_OLD_SO_LIST_SUCCESS: 'GET_OLD_SO_LIST_SUCCESS',
  GET_OLD_SO_LIST_ERROR: 'GET_OLD_SO_LIST_ERROR',

  PUT_OLD_SO_BEGIN: 'PUT_OLD_SO_BEGIN',
  PUT_OLD_SO_SUCCESS: 'PUT_OLD_SO_SUCCESS',
  PUT_OLD_SO_ERROR: 'PUT_OLD_SO_ERROR',

  GET_DEMAND_DOCUMENT_INFO_BEGIN: 'GET_DEMAND_DOCUMENT_INFO_BEGIN',
  GET_DEMAND_DOCUMENT_INFO_SUCCESS: 'GET_DEMAND_DOCUMENT_INFO_SUCCESS',
  GET_DEMAND_DOCUMENT_INFO_ERROR: 'GET_DEMAND_DOCUMENT_INFO_ERROR',

  ADD_DOCUMENT_TRACKING_BEGIN: 'ADD_DOCUMENT_TRACKING_BEGIN',
  ADD_DOCUMENT_TRACKING_SUCCESS: 'ADD_DOCUMENT_TRACKING_SUCCESS',
  ADD_DOCUMENT_TRACKING_ERROR: 'ADD_DOCUMENT_TRACKING_ERROR',

  DELETE_DOCUMENT_TRACKING_BEGIN: 'DELETE_DOCUMENT_TRACKING_BEGIN',
  DELETE_DOCUMENT_TRACKING_SUCCESS: 'DELETE_DOCUMENT_TRACKING_SUCCESS',
  DELETE_DOCUMENT_TRACKING_ERROR: 'DELETE_DOCUMENT_TRACKING_ERROR',

  UPDATE_DOCUMENT_TRACKING_BEGIN: 'UPDATE_DOCUMENT_TRACKING_BEGIN',
  UPDATE_DOCUMENT_TRACKING_SUCCESS: 'UPDATE_DOCUMENT_TRACKING_SUCCESS',
  UPDATE_DOCUMENT_TRACKING_ERROR: 'UPDATE_DOCUMENT_TRACKING_ERROR',

  GET_DOCUMENT_TRACKING_BEGIN: 'GET_DOCUMENT_TRACKING_BEGIN',
  GET_DOCUMENT_TRACKING_SUCCESS: 'GET_DOCUMENT_TRACKING_SUCCESS',
  GET_DOCUMENT_TRACKING_ERROR: 'GET_DOCUMENT_TRACKING_ERROR',

  GET_DOCUMENT_DETAIL_BEGIN: 'GET_DOCUMENT_DETAIL_BEGIN',
  GET_DOCUMENT_DETAIL_SUCCESS: 'GET_DOCUMENT_DETAIL_SUCCESS',
  GET_DOCUMENT_DETAIL_ERROR: 'GET_DOCUMENT_DETAIL_ERROR',

  ADD_DOCUMENT_PRODUCT_BEGIN: 'ADD_DOCUMENT_PRODUCT_BEGIN',
  ADD_DOCUMENT_PRODUCT_SUCCESS: 'ADD_DOCUMENT_PRODUCT_SUCCESS',
  ADD_DOCUMENT_PRODUCT_ERROR: 'ADD_DOCUMENT_PRODUCT_ERROR',

  UPDATE_DOCUMENT_PRODUCT_BEGIN: 'UPDATE_DOCUMENT_PRODUCT_BEGIN',
  UPDATE_DOCUMENT_PRODUCT_SUCCESS: 'UPDATE_DOCUMENT_PRODUCT_SUCCESS',
  UPDATE_DOCUMENT_PRODUCT_ERROR: 'UPDATE_DOCUMENT_PRODUCT_ERROR',

  GET_DOCUMENT_PRODUCT_BEGIN: 'GET_DOCUMENT_PRODUCT_BEGIN',
  GET_DOCUMENT_PRODUCT_SUCCESS: 'GET_DOCUMENT_PRODUCT_SUCCESS',
  GET_DOCUMENT_PRODUCT_ERROR: 'GET_DOCUMENT_PRODUCT_ERROR',

  ADD_ORDER_BEGIN: 'ADD_ORDER_BEGIN',
  ADD_ORDER_SUCCESS: 'ADD_ORDER_SUCCESS',
  ADD_ORDER_ERROR: 'ADD_ORDER_ERROR',

  UPDATE_ORDER_BEGIN: 'UPDATE_ORDER_BEGIN',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_ERROR: 'UPDATE_ORDER_ERROR',

  GET_ORDER_BEGIN: 'GET_ORDER_BEGIN',
  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  GET_ORDER_ERROR: 'GET_ORDER_ERROR',

  GET_GECICI_BEGIN: 'GET_GECICI_BEGIN',
  GET_GECICI_SUCCESS: 'GET_GECICI_SUCCESS',
  GET_GECICI_ERROR: 'GET_GECICI_ERROR',

  ADD_ORDER_NOTES_BEGIN: 'ADD_ORDER_NOTES_BEGIN',
  ADD_ORDER_NOTES_SUCCESS: 'ADD_ORDER_NOTES_SUCCESS',
  ADD_ORDER_NOTES_ERROR: 'ADD_ORDER_NOTES_ERROR',

  GET_ORDER_NOTES_BEGIN: 'GET_ORDER_NOTES_BEGIN',
  GET_ORDER_NOTES_SUCCESS: 'GET_ORDER_NOTES_SUCCESS',
  GET_ORDER_NOTES_ERROR: 'GET_ORDER_NOTES_ERROR',

  ADD_ORDER_DECLARATION_BEGIN: 'ADD_ORDER_DECLARATION_BEGIN',
  ADD_ORDER_DECLARATION_SUCCESS: 'ADD_ORDER_DECLARATION_SUCCESS',
  ADD_ORDER_DECLARATION_ERROR: 'ADD_ORDER_DECLARATION_ERROR',

  GET_ORDER_DECLARATION_BEGIN: 'GET_ORDER_DECLARATION_BEGIN',
  GET_ORDER_DECLARATION_SUCCESS: 'GET_ORDER_DECLARATION_SUCCESS',
  GET_ORDER_DECLARATION_ERROR: 'GET_ORDER_DECLARATION_ERROR',

  GET_DOCUMENT_REZERVED_BEGIN: 'GET_DOCUMENT_REZERVED_BEGIN',
  GET_DOCUMENT_REZERVED_SUCCESS: 'GET_DOCUMENT_REZERVED_SUCCESS',
  GET_DOCUMENT_REZERVED_ERROR: 'GET_DOCUMENT_REZERVED_ERROR',

  ORDER_REQUIRED_DOCUMENTS_BEGIN: 'ORDER_REQUIRED_DOCUMENTS_BEGIN',
  ORDER_REQUIRED_DOCUMENTS_SUCCESS: 'ORDER_REQUIRED_DOCUMENTS_SUCCESS',
  ORDER_REQUIRED_DOCUMENTS_ERROR: 'ORDER_REQUIRED_DOCUMENTS_ERROR',

  DOCUMENT_TRACKING_SEARCH_BEGIN: 'DOCUMENT_TRACKING_SEARCH_BEGIN',
  DOCUMENT_TRACKING_SEARCH_SUCCESS: 'DOCUMENT_TRACKING_SEARCH_SUCCESS',
  DOCUMENT_TRACKING_SEARCH_ERROR: 'DOCUMENT_TRACKING_SEARCH_ERROR',

  DOCUMENT_REZERVE_SAVE_BEGIN: 'DOCUMENT_REZERVE_SAVE_BEGIN',
  DOCUMENT_REZERVE_SAVE_SUCCESS: 'DOCUMENT_REZERVE_SAVE_SUCCESS',
  DOCUMENT_REZERVE_SAVE_ERROR: 'DOCUMENT_REZERVE_SAVE_ERROR',

  DOCUMENT_REZERVE_DELETE_BEGIN: 'DOCUMENT_REZERVE_DELETE_BEGIN',
  DOCUMENT_REZERVE_DELETE_SUCCESS: 'DOCUMENT_REZERVE_DELETE_SUCCESS',
  DOCUMENT_REZERVE_DELETE_ERROR: 'DOCUMENT_REZERVE_DELETE_ERROR',

  ORDER_ADD_EXCEL_BEGIN: 'ORDER_ADD_EXCEL_BEGIN',
  ORDER_ADD_EXCEL_SUCCESS: 'ORDER_ADD_EXCEL_SUCCESS',
  ORDER_ADD_EXCEL_ERROR: 'ORDER_ADD_EXCEL_ERROR',

  PIR_ADD_EXCEL_BEGIN: 'PIR_ADD_EXCEL_BEGIN',
  PIR_ADD_EXCEL_SUCCESS: 'PIR_ADD_EXCEL_SUCCESS',
  PIR_ADD_EXCEL_ERROR: 'PIR_ADD_EXCEL_ERROR',

  ADD_PR_EXCEL_BEGIN: 'ADD_PR_EXCEL_BEGIN',
  ADD_PR_EXCEL_SUCCESS: 'ADD_PR_EXCEL_SUCCESS',
  ADD_PR_EXCEL_ERROR: 'ADD_PR_EXCEL_ERROR',

  GET_ORDER_LOGS_BEGIN: 'GET_ORDER_LOGS_BEGIN',
  GET_ORDER_LOGS_SUCCESS: 'GET_ORDER_LOGS_SUCCESS',
  GET_ORDER_LOGS_ERROR: 'GET_ORDER_LOGS_ERROR',

  GET_GECICI_ITHALAT_BEGIN: 'GET_GECICI_ITHALAT_BEGIN',
  GET_GECICI_ITHALAT_SUCCESS: 'GET_GECICI_ITHALAT_SUCCESS',
  GET_GECICI_ITHALAT_ERROR: 'GET_GECICI_ITHALAT_ERROR',

  UPDATE_GECICI_ITHALAT_BEGIN: 'UPDATE_GECICI_ITHALAT_BEGIN',
  UPDATE_GECICI_ITHALAT_SUCCESS: 'UPDATE_GECICI_ITHALAT_SUCCESS',
  UPDATE_GECICI_ITHALAT_ERROR: 'UPDATE_GECICI_ITHALAT_ERROR',

  GET_ORIGINAL_DOCUMENT_TRACKING_LIST_BEGIN:
    'GET_ORIGINAL_DOCUMENT_TRACKING_LIST_BEGIN',
  GET_ORIGINAL_DOCUMENT_TRACKING_LIST_SUCCESS:
    'GET_ORIGINAL_DOCUMENT_TRACKING_LIST_SUCCESS',
  GET_ORIGINAL_DOCUMENT_TRACKING_LIST_ERROR:
    'GET_ORIGINAL_DOCUMENT_TRACKING_LIST_ERROR',

  GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_BEGIN:
    'GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_BEGIN',
  GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_SUCCESS:
    'GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_SUCCESS',
  GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_ERROR:
    'GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_ERROR',

  ADD_ORIGINAL_DOCUMENT_TRACKING_BEGIN: 'ADD_ORIGINAL_DOCUMENT_TRACKING_BEGIN',
  ADD_ORIGINAL_DOCUMENT_TRACKING_SUCCESS:
    'ADD_ORIGINAL_DOCUMENT_TRACKING_SUCCESS',
  ADD_ORIGINAL_DOCUMENT_TRACKING_ERROR: 'ADD_ORIGINAL_DOCUMENT_TRACKING_ERROR',

  UPDATE_ORIGINAL_DOCUMENT_TRACKING_BEGIN:
    'UPDATE_ORIGINAL_DOCUMENT_TRACKING_BEGIN',
  UPDATE_ORIGINAL_DOCUMENT_TRACKING_SUCCESS:
    'UPDATE_ORIGINAL_DOCUMENT_TRACKING_SUCCESS',
  UPDATE_ORIGINAL_DOCUMENT_TRACKING_ERROR:
    'UPDATE_ORIGINAL_DOCUMENT_TRACKING_ERROR',

  GET_ORIGINAL_DOCUMENT_TYPE_LIST_BEGIN:
    'GET_ORIGINAL_DOCUMENT_TYPE_LIST_BEGIN',
  GET_ORIGINAL_DOCUMENT_TYPE_LIST_SUCCESS:
    'GET_ORIGINAL_DOCUMENT_TYPE_LIST_SUCCESS',
  GET_ORIGINAL_DOCUMENT_TYPE_LIST_ERROR:
    'GET_ORIGINAL_DOCUMENT_TYPE_LIST_ERROR',

  GET_PRODUCT_MASTAR_DATA_BEGIN: 'GET_PRODUCT_MASTAR_DATA_BEGIN',
  GET_PRODUCT_MASTAR_DATA_SUCCESS: 'GET_PRODUCT_MASTAR_DATA_SUCCESS',
  GET_PRODUCT_MASTER_DATA_ERROR: 'GET_PRODUCT_MASTER_DATA_ERROR',

  UPDATE_PRODUCT_MASTER_DATA_BEGIN: 'UPDATE_PRODUCT_MASTER_DATA_BEGIN',
  UPDATE_PRODUCT_MASTER_DATA_SUCCESS: 'UPDATE_PRODUCT_MASTER_DATA_SUCCESS',
  UPDATE_PRODUCT_MASTER_DATA_ERROR: 'UPDATE_PRODUCT_MASTER_DATA_ERROR',

  ADD_PRODUCT_MASTER_DATA_BEGIN: 'ADD_PRODUCT_MASTER_DATA_BEGIN',
  ADD_PRODUCT_MASTER_DATA_SUCCESS: 'ADD_PRODUCT_MASTER_DATA_SUCCESS',
  ADD_PRODUCT_MASTER_DATA_ERROR: 'ADD_PRODUCT_MASTER_DATA_ERROR',

  CONVERT_ORDERS_TO_EXCEL_REPORT_BEGIN: 'CONVERT_ORDERS_TO_EXCEL_REPORT_BEGIN',
  CONVERT_ORDERS_TO_EXCEL_REPORT_SUCCES:
    'CONVERT_ORDERS_TO_EXCEL_REPORT_SUCCES',
  CONVERT_ORDERS_TO_EXCEL_REPORT_ERROR: 'CONVERT_ORDERS_TO_EXCEL_REPORT_ERROR',

  GET_ORDER_AGENT_INFORMATION_BEGIN: 'GET_ORDER_AGENT_INFORMATION_BEGIN',
  GET_ORDER_AGENT_INFORMATION_SUCCESS: 'GET_ORDER_AGENT_INFORMATION_SUCCESS',
  GET_ORDER_AGENT_INFORMATION_ERROR: 'GET_ORDER_AGENT_INFORMATION_ERROR',

  GET_ORDER_BY_ORDER_ID_BEGIN: 'GET_ORDER_BY_ORDER_ID_BEGIN',
  GET_ORDER_BY_ORDER_ID_SUCCESS: 'GET_ORDER_BY_ORDER_ID_SUCCESS',
  GET_ORDER_BY_ORDER_ID_ERROR: 'GET_ORDER_BY_ORDER_ID_ERROR',

  UPDATE_ORDER_AGENT_BEGIN: 'UPDATE_ORDER_AGENT_BEGIN',
  UPDATE_ORDER_AGENT_SUCCESS: 'UPDATE_ORDER_AGENT_SUCCESS',
  UPDATE_ORDER_AGENT_ERROR: 'UPDATE_ORDER_AGENT_ERROR',

  ADD_GECICI_EXPORT_BEGIN: 'ADD_GECICI_EXPORT_BEGIN',
  ADD_GECICI_EXPORT_SUCCESS: 'ADD_GECICI_EXPORT_SUCCESS',
  ADD_GECICI_EXPORT_ERROR: 'ADD_GECICI_EXPORT_ERROR',

  GET_GECICI_EXPORT_BEGIN: 'GET_GECICI_EXPORT_BEGIN',
  GET_GECICI_EXPORT_SUCCESS: 'GET_GECICI_EXPORT_SUCCESS',
  GET_GECICI_EXPORT_ERROR: 'GET_GECICI_EXPORT_ERROR',

  GET_WANTED_PRODUCTS_BEGIN: 'GET_WANTED_PRODUCTS_BEGIN',
  GET_WANTED_PRODUCTS_SUCCESS: 'GET_WANTED_PRODUCTS_SUCCESS',
  GET_WANTED_PRODUCTS_ERROR: 'GET_WANTED_PRODUCTS_ERROR',

  CEZALAR_TO_EXCEL_REPORT_BEGIN: 'CEZALAR_TO_EXCEL_REPORT_BEGIN',
  CEZALAR_TO_EXCEL_REPORT_SUCCESS: 'CEZALAR_TO_EXCEL_REPORT_SUCCESS',
  CEZALAR_TO_EXCEL_REPORT_ERROR: 'CEZALAR_TO_EXCEL_REPORT_ERROR',

  GET_SHIPPING_LINE_BEGIN: 'GET_SHIPPING_LINE_BEGIN',
  GET_SHIPPING_LINE_SUCCESS: 'GET_SHIPPING_LINE_SUCCESS',
  GET_SHIPPING_LINE_ERROR: 'GET_SHIPPING_LINE_ERROR',

  GET_DAHILDE_ISLEME_BEGIN: 'GET_DAHILDE_ISLEME_BEGIN',
  GET_DAHILDE_ISLEME_SUCCESS: 'GET_DAHILDE_ISLEME_SUCCESS',
  GET_DAHILDE_ISLEME_ERROR: 'GET_DAHILDE_ISLEME_ERROR',

  ADD_COMMON_NUMBER_BEGIN: 'ADD_COMMON_NUMBER_BEGIN',
  ADD_COMMON_NUMBER_SUCCESS: 'ADD_COMMON_NUMBER_SUCCESS',
  ADD_COMMON_NUMBER_ERROR: 'ADD_COMMON_NUMBER_ERROR',

  UPDATE_ORDER_MULTIPLE_BEGIN: 'UPDATE_ORDER_MULTIPLE_BEGIN',
  UPDATE_ORDER_MULTIPLE_SUCCESS: 'UPDATE_ORDER_MULTIPLE_SUCCESS',
  UPDATE_ORDER_MULTIPLE_ERROR: 'UPDATE_ORDER_MULTIPLE_ERROR',

  ADD_ORDER_MULTIPLE_DECLARATION_BEGIN: 'ADD_ORDER_MULTIPLE_DECLARATION_BEGIN',
  ADD_ORDER_MULTIPLE_DECLARATION_SUCCESS:
    'ADD_ORDER_MULTIPLE_DECLARATION_SUCCESS',
  ADD_ORDER_MULTIPLE_DECLARATION_ERROR: 'ADD_ORDER_MULTIPLE_DECLARATION_ERROR',

  BELGELER_TO_EXCEL_REPORT_BEGIN: 'BELGELER_TO_EXCEL_REPORT_BEGIN',
  BELGELER_TO_EXCEL_REPORT_SUCCESS: 'BELGELER_TO_EXCEL_REPORT_SUCCESS',
  BELGELER_TO_EXCEL_REPORT_ERROR: 'BELGELER_TO_EXCEL_REPORT_ERROR',

  GET_ORDER_FILTER_BEGIN: 'GET_ORDER_FILTER_BEGIN',
  GET_ORDER_FILTER_SUCCESS: 'GET_ORDER_FILTER_SUCCESS',
  GET_ORDER_FILTER_ERROR: 'GET_ORDER_FILTER_ERROR',

  GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_BEGIN:
    'GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_BEGIN',
  GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_SUCCESS:
    'GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_SUCCESS',
  GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_ERROR:
    'GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_ERROR',

  GET_FILTERING_PRODUCTS_BEGIN: 'GET_FILTERING_PRODUCTS_BEGIN',
  GET_FILTERING_PRODUCTS_SUCCESS: 'GET_FILTERING_PRODUCTS_SUCCESS',
  GET_FILTERING_PRODUCTS_ERROR: 'GET_FILTERING_PRODUCTS_ERROR',

  ADD_MULTIPLE_ORDER_NOTES_BEGIN: 'ADD_MULTIPLE_ORDER_NOTES_BEGIN',
  ADD_MULTIPLE_ORDER_NOTES_SUCCESS: 'ADD_MULTIPLE_ORDER_NOTES_SUCCESS',
  ADD_MULTIPLE_ORDER_NOTES_ERROR: 'ADD_MULTIPLE_ORDER_NOTES_ERROR',

  ADD_ORDER_CREDIT_NOTE_BEGIN: 'ADD_ORDER_CREDIT_NOTE_BEGIN',
  ADD_ORDER_CREDIT_NOTE_SUCCESS: 'ADD_ORDER_CREDIT_NOTE_SUCCESS',
  ADD_ORDER_CREDIT_NOTE_ERROR: 'ADD_ORDER_CREDIT_NOTE_ERROR',

  GET_EVRIM_CEZA_BY_ORDER_BEGIN: 'GET_EVRIM_CEZA_BY_ORDER_BEGIN',
  GET_EVRIM_CEZA_BY_ORDER_SUCCESS: 'GET_EVRIM_CEZA_BY_ORDER_SUCCESS',
  GET_EVRIM_CEZA_BY_ORDER_ERROR: 'GET_EVRIM_CEZA_BY_ORDER_ERROR',

  GET_ORDER_URGENT_CHANGE_BEGIN: 'GET_ORDER_URGENT_CHANGE_BEGIN',
  GET_ORDER_URGENT_CHANGE_SUCCESS: 'GET_ORDER_URGENT_CHANGE_SUCCESS',
  GET_ORDER_URGENT_CHANGE_ERROR: 'GET_ORDER_URGENT_CHANGE_ERROR',

  GET_ORDER_DEMURAJ_CONTROL_BEGIN: 'GET_ORDER_DEMURAJ_CONTROL_BEGIN',
  GET_ORDER_DEMURAJ_CONTROL_SUCCESS: 'GET_ORDER_DEMURAJ_CONTROL_SUCCESS',
  GET_ORDER_DEMURAJ_CONTROL_ERROR: 'GET_ORDER_DEMURAJ_CONTROL_ERROR',

  ADD_ORDER_MASRAF_BEGIN: 'ADD_ORDER_MASRAF_BEGIN',
  ADD_ORDER_MASRAF_SUCCESS: 'ADD_ORDER_MASRAF_SUCCESS',
  ADD_ORDER_MASRAF_ERROR: 'ADD_ORDER_MASRAF_ERROR',

  GET_ORDER_REQUIRED_BEGIN: 'GET_ORDER_REQUIRED_BEGIN',
  GET_ORDER_REQUIRED_SUCCESS: 'GET_ORDER_REQUIRED_SUCCESS',
  GET_ORDER_REQUIRED_ERROR: 'GET_ORDER_REQUIRED_ERROR',

  ADD_IHBAR_TAKIP_BEGIN: 'ADD_IHBAR_TAKIP_BEGIN',
  ADD_IHBAR_TAKIP_SUCCESS: 'ADD_IHBAR_TAKIP_SUCCESS',
  ADD_IHBAR_TAKIP_ERROR: 'ADD_IHBAR_TAKIP_ERROR',

  UPDATE_IHBAR_TAKIP_BEGIN: 'UPDATE_IHBAR_TAKIP_BEGIN',
  UPDATE_IHBAR_TAKIP_SUCCESS: 'UPDATE_IHBAR_TAKIP_SUCCESS',
  UPDATE_IHBAR_TAKIP_ERROR: 'UPDATE_IHBAR_TAKIP_ERROR',

  GET_IHBAR_TAKIP_BEGIN: 'GET_IHBAR_TAKIP_BEGIN',
  GET_IHBAR_TAKIP_SUCCESS: 'GET_IHBAR_TAKIP_SUCCESS',
  GET_IHBAR_TAKIP_ERROR: 'GET_IHBAR_TAKIP_ERROR',

  ADD_ORDER_REQUIRED_BEGIN: 'ADD_ORDER_REQUIRED_BEGIN',
  ADD_ORDER_REQUIRED_SUCCESS: 'ADD_ORDER_REQUIRED_SUCCESS',
  ADD_ORDER_REQUIRED_ERROR: 'ADD_ORDER_REQUIRED_ERROR',

  DELETE_ORDER_REQUIRED_BEGIN: 'DELETE_ORDER_REQUIRED_BEGIN',
  DELETE_ORDER_REQUIRED_SUCCESS: 'DELETE_ORDER_REQUIRED_SUCCESS',
  DELETE_ORDER_REQUIRED_ERROR: 'DELETE_ORDER_REQUIRED_ERROR',

  UPDATE_REQUIRED_ORDER_BEGIN: 'UPDATE_REQUIRED_ORDER_BEGIN',
  UPDATE_REQUIRED_ORDER_SUCCESS: 'UPDATE_REQUIRED_ORDER_SUCCESS',
  UPDATE_REQUIRED_ORDER_ERROR: 'UPDATE_REQUIRED_ORDER_ERROR',

  DELETE_ORDER_DECLARATION_BEGIN: 'DELETE_ORDER_DECLARATION_BEGIN',
  DELETE_ORDER_DECLARATION_SUCCESS: 'DELETE_ORDER_DECLARATION_SUCCESS',
  DELETE_ORDER_DECLARATION_ERROR: 'DELETE_ORDER_DECLARATION_ERROR',

  ADD_COMPANY_BEGIN: 'ADD_COMPANY_BEGIN',
  ADD_COMPANY_SUCCESS: 'ADD_COMPANY_SUCCESS',
  ADD_COMPANY_ERROR: 'ADD_COMPANY_ERROR',

  ADD_PRODUCT_BASIC_BEGIN: 'ADD_PRODUCT_BASIC_BEGIN',
  ADD_PRODUCT_BASIC_SUCCESS: 'ADD_PRODUCT_BASIC_SUCCESS',
  ADD_PRODUCT_BASIC_ERROR: 'ADD_PRODUCT_BASIC_ERROR',

  ADD_ACENTE_DEFINITIONS_BEGIN: 'ADD_ACENTE_DEFINITIONS_BEGIN',
  ADD_ACENTE_DEFINITIONS_SUCCESS: 'ADD_ACENTE_DEFINITIONS_SUCCESS',
  ADD_ACENTE_DEFINITIONS_ERROR: 'ADD_ACENTE_DEFINITIONS_ERROR',

  ADD_ARIBA_EXCEL_BEGIN: 'ADD_ARIBA_EXCEL_BEGIN',
  ADD_ARIBA_EXCEL_SUCCESS: 'ADD_ARIBA_EXCEL_SUCCESS',
  ADD_ARIBA_EXCEL_ERROR: 'ADD_ARIBA_EXCEL_ERROR',

  GET_DONUS_DEPO_BEGIN: 'GET_DONUS_DEPO_BEGIN',
  GET_DONUS_DEPO_SUCCESS: 'GET_DONUS_DEPO_SUCCESS',
  GET_DONUS_DEPO_ERROR: 'GET_DONUS_DEPO_ERROR',

  GET_MASRAF_LIST_BEGIN: 'GET_MASRAF_LIST_BEGIN',
  GET_MASRAF_LIST_SUCCESS: 'GET_MASRAF_LIST_SUCCESS',
  GET_MASRAF_LIST_ERROR: 'GET_MASRAF_LIST_ERROR',

  GET_MASRAF_DETAY_BEGIN: 'GET_MASRAF_DETAY_BEGIN',
  GET_MASRAF_DETAY_SUCCESS: 'GET_MASRAF_DETAY_SUCCESS',
  GET_MASRAF_DETAY_ERROR: 'GET_MASRAF_DETAY_ERROR',

  GET_MASRAF_TIP_BEGIN: 'GET_MASRAF_TIP_BEGIN',
  GET_MASRAF_TIP_SUCCESS: 'GET_MASRAF_TIP_SUCCESS',
  GET_MASRAF_TIP_ERROR: 'GET_MASRAF_TIP_ERROR',

  ADD_MASRAF_TIP_SAVE_BEGIN: 'ADD_MASRAF_TIP_SAVE_BEGIN',
  ADD_MASRAF_TIP_SAVE_SUCCESS: 'ADD_MASRAF_TIP_SAVE_SUCCESS',
  ADD_MASRAF_TIP_SAVE_ERROR: 'ADD_MASRAF_TIP_SAVE_ERROR',

  ADD_MASRAF_BEYANNAME_NO_BEGIN: 'ADD_MASRAF_BEYANNAME_NO_BEGIN',
  ADD_MASRAF_BEYANNAME_NO_SUCCESS: 'ADD_MASRAF_BEYANNAME_NO_SUCCESS',
  ADD_MASRAF_BEYANNAME_NO_ERROR: 'ADD_MASRAF_BEYANNAME_NO_ERROR',

  GET_MASRAF_BEYANNAME_BEGIN: 'GET_MASRAF_BEYANNAME_BEGIN',
  GET_MASRAF_BEYANNAME_SUCCESS: 'GET_MASRAF_BEYANNAME_SUCCESS',
  GET_MASRAF_BEYANNAME_ERROR: 'GET_MASRAF_BEYANNAME_ERROR',

  DELETE_MASRAF_BEYANNAME_BEGIN: 'DELETE_MASRAF_BEYANNAME_BEGIN',
  DELETE_MASRAF_BEYANNAME_SUCCESS: 'DELETE_MASRAF_BEYANNAME_SUCCESS',
  DELETE_MASRAF_BEYANNAME_ERROR: 'DELETE_MASRAF_BEYANNAME_ERROR',

  DELETE_MASRAF_FATURA_BEGIN: 'DELETE_MASRAF_FATURA_BEGIN',
  DELETE_MASRAF_FATURA_SUCCESS: 'DELETE_MASRAF_FATURA_SUCCESS',
  DELETE_MASRAF_FATURA_ERROR: 'DELETE_MASRAF_FATURA_ERROR',

  DELETE_ORDER_NOTES_BEGIN: 'DELETE_ORDER_NOTES_BEGIN',
  DELETE_ORDER_NOTES_SUCCESS: 'DELETE_ORDER_NOTES_SUCCESS',
  DELETE_ORDER_NOTES_ERROR: 'DELETE_ORDER_NOTES_ERROR',

  UPDATE_ORDER_TRANSPORT_BEGIN: 'UPDATE_ORDER_TRANSPORT_BEGIN',
  UPDATE_ORDER_TRANSPORT_SUCCESS: 'UPDATE_ORDER_TRANSPORT_SUCCESS',
  UPDATE_ORDER_TRANSPORT_ERROR: 'UPDATE_ORDER_TRANSPORT_ERROR',

  ORDER_MASRAF_EXCEL_BEGIN: 'ORDER_MASRAF_EXCEL_BEGIN',
  ORDER_MASRAF_EXCEL_SUCCESS: 'ORDER_MASRAF_EXCEL_SUCCESS',
  ORDER_MASRAF_EXCEL_ERROR: 'ORDER_MASRAF_EXCEL_ERROR',

  GET_FIRMA_EVRIM_BEGIN: 'GET_FIRMA_EVRIM_BEGIN',
  GET_FIRMA_EVRIM_SUCCESS: 'GET_FIRMA_EVRIM_SUCCESS',
  GET_FIRMA_EVRIM_ERROR: 'GET_FIRMA_EVRIM_ERROR',

  GET_PRODUCT_TARIFF_LIST_BEGIN: 'GET_PRODUCT_TARIFF_LIST_BEGIN',
  GET_PRODUCT_TARIFF_LIST_SUCCESS: 'GET_PRODUCT_TARIFF_LIST_SUCCESS',
  GET_PRODUCT_TARIFF_LIST_ERROR: 'GET_PRODUCT_TARIFF_LIST_ERROR',

  GET_PRODUCT_TARIFF_DRAFT_BEGIN: 'GET_PRODUCT_TARIFF_DRAFT_BEGIN',
  GET_PRODUCT_TARIFF_DRAFT_SUCCESS: 'GET_PRODUCT_TARIFF_DRAFT_SUCCESS',
  GET_PRODUCT_TARIFF_DRAFT_ERROR: 'GET_PRODUCT_TARIFF_DRAFT_ERROR',

  GET_CHECK_PRODUCT_TARIFF_DRAFT_BEGIN: 'GET_CHECK_PRODUCT_TARIFF_DRAFT_BEGIN',
  GET_CHECK_PRODUCT_TARIFF_DRAFT_SUCCESS:
    'GET_CHECK_PRODUCT_TARIFF_DRAFT_SUCCESS',
  GET_CHECK_PRODUCT_TARIFF_DRAFT_ERROR: 'GET_CHECK_PRODUCT_TARIFF_DRAFT_ERROR',

  GET_PRODUCT_TARIFF_MOVE_LIST_BEGIN: 'GET_PRODUCT_TARIFF_MOVE_LIST_BEGIN',
  GET_PRODUCT_TARIFF_MOVE_LIST_SUCCESS: 'GET_PRODUCT_TARIFF_MOVE_LIST_SUCCESS',
  GET_PRODUCT_TARIFF_MOVE_LIST_ERROR: 'GET_PRODUCT_TARIFF_MOVE_LIST_ERROR',

  GET_ONE_PRODUCT_TARIFF_BEGIN: 'GET_ONE_PRODUCT_TARIFF_BEGIN',
  GET_ONE_PRODUCT_TARIFF_SUCCESS: 'GET_ONE_PRODUCT_TARIFF_SUCCESS',
  GET_ONE_PRODUCT_TARIFF_ERROR: 'GET_ONE_PRODUCT_TARIFF_ERROR',

  POST_PRODUCT_TARIFF_BEGIN: 'POST_PRODUCT_TARIFF_BEGIN',
  POST_PRODUCT_TARIFF_SUCCESS: 'POST_PRODUCT_TARIFF_SUCCESS',
  POST_PRODUCT_TARIFF_ERROR: 'POST_PRODUCT_TARIFF_ERROR',

  PATCH_PRODUCT_TARIFF_BEGIN: 'PATCH_PRODUCT_TARIFF_BEGIN',
  PATCH_PRODUCT_TARIFF_SUCCESS: 'PATCH_PRODUCT_TARIFF_SUCCESS',
  PATCH_PRODUCT_TARIFF_ERROR: 'PATCH_PRODUCT_TARIFF_ERROR',

  PATCH_PRODUCT_TARIFF_CONFIRMATION_BEGIN:
    'PATCH_PRODUCT_TARIFF_CONFIRMATION_BEGIN',
  PATCH_PRODUCT_TARIFF_CONFIRMATION_SUCCESS:
    'PATCH_PRODUCT_TARIFF_CONFIRMATION_SUCCESS',
  PATCH_PRODUCT_TARIFF_CONFIRMATION_ERROR:
    'PATCH_PRODUCT_TARIFF_CONFIRMATION_ERROR',

  DELETE_PRODUCT_TARIFF_BEGIN: 'DELETE_PRODUCT_TARIFF_BEGIN',
  DELETE_PRODUCT_TARIFF_SUCCESS: 'DELETE_PRODUCT_TARIFF_SUCCESS',
  DELETE_PRODUCT_TARIFF_ERROR: 'DELETE_PRODUCT_TARIFF_ERROR',

  GET_MUSAVIR_LIST_BEGIN: 'GET_MUSAVIR_LIST_BEGIN',
  GET_MUSAVIR_LIST_SUCCESS: 'GET_MUSAVIR_LIST_SUCCESS',
  GET_MUSAVIR_LIST_ERROR: 'GET_MUSAVIR_LIST_ERROR',

  MASRAF_FTP_UPLOAD_BEGIN: 'MASRAF_FTP_UPLOAD_BEGIN',
  MASRAF_FTP_UPLOAD_SUCCESS: 'MASRAF_FTP_UPLOAD_SUCCESS',
  MASRAF_FTP_UPLOAD_ERROR: 'MASRAF_FTP_UPLOAD_ERROR',

  DEKONT_EXCEL_DOWNLOAD_BEGIN: 'DEKONT_EXCEL_DOWNLOAD_BEGIN',
  DEKONT_EXCEL_DOWNLOAD_SUCCESS: 'DEKONT_EXCEL_DOWNLOAD_SUCCESS',
  DEKONT_EXCEL_DOWNLOAD_ERROR: 'DEKONT_EXCEL_DOWNLOAD_ERROR',

  SIGORTA_CREATE_BEGIN: 'SIGORTA_CREATE_BEGIN',
  SIGORTA_CREATE_SUCCESS: 'SIGORTA_CREATE_SUCCESS',
  SIGORTA_CREATE_ERROR: 'SIGORTA_CREATE_ERROR',

  ADD_BEYANNAME_TASLAK_BEGIN: 'ADD_BEYANNAME_TASLAK_BEGIN',
  ADD_BEYANNAME_TASLAK_SUCCESS: 'ADD_BEYANNAME_TASLAK_SUCCESS',
  ADD_BEYANNAME_TASLAK_ERROR: 'ADD_BEYANNAME_TASLAK_ERROR',

  GET_BEYANNAME_TASLAK_BEGIN: 'GET_BEYANNAME_TASLAK_BEGIN',
  GET_BEYANNAME_TASLAK_SUCCESS: 'GET_BEYANNAME_TASLAK_SUCCESS',
  GET_BEYANNAME_TASLAK_ERROR: 'GET_BEYANNAME_TASLAK_ERROR',

  GET_BEYANNAME_LIST_BEGIN: 'GET_BEYANNAME_LIST_BEGIN',
  GET_BEYANNAME_LIST_SUCCESS: 'GET_BEYANNAME_LIST_SUCCESS',
  GET_BEYANNAME_LIST_ERROR: 'GET_BEYANNAME_LIST_ERROR',

  UPDATE_GGBMASTER_BEGIN: 'UPDATE_GGBMASTER_BEGIN',
  UPDATE_GGBMASTER_SUCCESS: 'UPDATE_GGBMASTER_SUCCESS',
  UPDATE_GGBMASTER_ERROR: 'UPDATE_GGBMASTER_ERROR',

  UPDATE_GGBDETAY_BEGIN: 'UPDATE_GGBDETAY_BEGIN',
  UPDATE_GGBDETAY_SUCCESS: 'UPDATE_GGBDETAY_SUCCESS',
  UPDATE_GGBDETAY_ERROR: 'UPDATE_GGBDETAY_ERROR',

  UPDATE_TOPLAM_BRUT_DAGIT_BEGIN: 'UPDATE_TOPLAM_BRUT_DAGIT_BEGIN',
  UPDATE_TOPLAM_BRUT_DAGIT_SUCCESS: 'UPDATE_TOPLAM_BRUT_DAGIT_SUCCESS',
  UPDATE_TOPLAM_BRUT_DAGIT_ERROR: 'UPDATE_TOPLAM_BRUT_DAGIT_ERROR',

  ADD_DONUS_DEPO_BEGIN: 'ADD_DONUS_DEPO_BEGIN',
  ADD_DONUS_DEPO_SUCCESS: 'ADD_DONUS_DEPO_SUCCESS',
  ADD_DONUS_DEPO_ERROR: 'ADD_DONUS_DEPO_ERROR',

  GET_ONAYSIZ_DEKONT_LIST_BEGIN: 'GET_ONAYSIZ_DEKONT_LIST_BEGIN',
  GET_ONAYSIZ_DEKONT_LIST_SUCCESS: 'GET_ONAYSIZ_DEKONT_LIST_SUCCESS',
  GET_ONAYSIZ_DEKONT_LIST_ERROR: 'GET_ONAYSIZ_DEKONT_LIST_ERROR',

  UPDATE_DEKONT_ONAY_BEGIN: 'UPDATE_DEKONT_ONAY_BEGIN',
  UPDATE_DEKONT_ONAY_SUCCESS: 'UPDATE_DEKONT_ONAY_SUCCESS',
  UPDATE_DEKONT_ONAY_ERROR: 'UPDATE_DEKONT_ONAY_ERROR',

  DEKONT_FIRMA_BILGI_EXCEL_BEGIN: 'DEKONT_FIRMA_BILGI_EXCEL_BEGIN',
  DEKONT_FIRMA_BILGI_EXCEL_SUCCESS: 'DEKONT_FIRMA_BILGI_EXCEL_SUCCESS',
  DEKONT_FIRMA_BILGI_EXCEL_ERROR: 'DEKONT_FIRMA_BILGI_EXCEL_ERROR',

  DEKONT_HATA_BILDIR_BEGIN: 'DEKONT_HATA_BILDIR_BEGIN',
  DEKONT_HATA_BILDIR_SUCCESS: 'DEKONT_HATA_BILDIR_SUCCESS',
  DEKONT_HATA_BILDIR_ERROR: 'DEKONT_HATA_BILDIR_ERROR',

  MASRAF_TIP_PROCESS_BEGIN: 'MASRAF_TIP_PROCESS_BEGIN',
  MASRAF_TIP_PROCESS_SUCCESS: 'MASRAF_TIP_PROCESS_SUCCESS',
  MASRAF_TIP_PROCESS_ERROR: 'MASRAF_TIP_PROCESS_ERROR',

  deleteDocumentBegin: () => {
    return {
      type: actions.DELETE_DOCUMENT_BEGIN,
    };
  },

  deleteDocumentSuccess: data => {
    return {
      type: actions.DELETE_DOCUMENT_SUCCESS,
      data,
    };
  },

  deleteDocumentError: err => {
    return {
      type: actions.DELETE_DOCUMENT_ERROR,
      err,
    };
  },

  getWebGumrukBeyannameBegin: () => {
    return {
      type: actions.GET_WEBGUMRUK_BEYANNAME_BEGIN,
    };
  },

  getWebGumrukBeyannameSuccess: data => {
    return {
      type: actions.GET_WEBGUMRUK_BEYANNAME_SUCCESS,
      data,
    };
  },

  getWebGumrukBeyannameError: err => {
    return {
      type: actions.GET_WEBGUMRUK_BEYANNAME_ERROR,
      err,
    };
  },

  getPaymentBegin: () => {
    return {
      type: actions.GET_PAYMENT_BEGIN,
    };
  },

  getPaymentSuccess: data => {
    return {
      type: actions.GET_PAYMENT_SUCCESS,
      data,
    };
  },

  getPaymentError: err => {
    return {
      type: actions.GET_PAYMENT_ERROR,
      err,
    };
  },

  getCountriesBegin: () => {
    return {
      type: actions.GET_COUNTRIES_BEGIN,
    };
  },

  getCountriesSuccess: data => {
    return {
      type: actions.GET_COUNTRIES_SUCCESS,
      data,
    };
  },

  getCountriesError: err => {
    return {
      type: actions.GET_COUNTRIES_ERROR,
      err,
    };
  },

  addPaymentDeclarationBegin: () => {
    return {
      type: actions.ADD_PAYMENT_DECLARATION_BEGIN,
    };
  },

  addPaymentDeclarationSuccess: data => {
    return {
      type: actions.ADD_PAYMENT_DECLARATION_SUCCESS,
      data,
    };
  },

  addPaymentDeclarationError: err => {
    return {
      type: actions.ADD_PAYMENT_DECLARATION_ERROR,
      err,
    };
  },

  updatePaymentDeclarationBegin: () => {
    return {
      type: actions.UPDATE_PAYMENT_DECLARATION_BEGIN,
    };
  },

  updatePaymentDeclarationSuccess: data => {
    return {
      type: actions.UPDATE_PAYMENT_DECLARATION_SUCCESS,
      data,
    };
  },

  updatePaymentDeclarationError: err => {
    return {
      type: actions.UPDATE_PAYMENT_DECLARATION_ERROR,
      err,
    };
  },

  uploadGitBegin: () => {
    return {
      type: actions.UPLOAD_GIT_BEGIN,
    };
  },

  uploadGitSuccess: data => {
    return {
      type: actions.UPLOAD_GIT_SUCCESS,
      data,
    };
  },

  uploadGitError: err => {
    return {
      type: actions.UPLOAD_GIT_ERROR,
      err,
    };
  },

  addGitBegin: () => {
    return {
      type: actions.ADD_GIT_BEGIN,
    };
  },

  addGitSuccess: data => {
    return {
      type: actions.ADD_GIT_SUCCESS,
      data,
    };
  },

  addGitError: err => {
    return {
      type: actions.ADD_GIT_ERROR,
      err,
    };
  },

  getGitInvoicesBegin: () => {
    return {
      type: actions.GET_GIT_INVOICES_BEGIN,
    };
  },

  getGitInvoicesSuccess: data => {
    return {
      type: actions.GET_GIT_INVOICES_SUCCESS,
      data,
    };
  },

  getGitInvoicesError: err => {
    return {
      type: actions.GET_GIT_INVOICES_ERROR,
      err,
    };
  },

  addGitConsignmentBegin: () => {
    return {
      type: actions.ADD_GIT_CONSIGNMENTNOTE_BEGIN,
    };
  },

  addGitConsignmentSuccess: data => {
    return {
      type: actions.ADD_GIT_CONSIGNMENTNOTE_SUCCESS,
      data,
    };
  },

  addGitConsignmentError: err => {
    return {
      type: actions.ADD_GIT_CONSIGNMENTNOTE_ERROR,
      err,
    };
  },

  updateGitConsignmentBegin: () => {
    return {
      type: actions.UPDATE_GIT_CONSIGNMENTNOTE_BEGIN,
    };
  },

  updateGitConsignmentSuccess: data => {
    return {
      type: actions.UPDATE_GIT_CONSIGNMENTNOTE_SUCCESS,
      data,
    };
  },

  updateGitConsignmentError: err => {
    return {
      type: actions.UPDATE_GIT_CONSIGNMENTNOTE_ERROR,
      err,
    };
  },

  updateInvoicesEtaBegin: () => {
    return {
      type: actions.UPDATE_INVOICES_ETA_BEGIN,
    };
  },

  updateInvoicesEtaSuccess: data => {
    return {
      type: actions.UPDATE_INVOICES_ETA_SUCCESS,
      data,
    };
  },

  updateInvoicesEtaError: err => {
    return {
      type: actions.UPDATE_INVOICES_ETA_ERROR,
      err,
    };
  },

  getSoListBegin: () => {
    return {
      type: actions.GET_SO_LIST_BEGIN,
    };
  },

  getSoListSuccess: data => {
    return {
      type: actions.GET_SO_LIST_SUCCESS,
      data,
    };
  },

  getSoListError: err => {
    return {
      type: actions.GET_SO_LIST_ERROR,
      err,
    };
  },

  getSoProductBegin: () => {
    return {
      type: actions.GET_SO_PRODUCT_BEGIN,
    };
  },

  getSoProductSuccess: data => {
    return {
      type: actions.GET_SO_PRODUCT_SUCCESS,
      data,
    };
  },

  getSoProductError: err => {
    return {
      type: actions.GET_SO_PRODUCT_ERROR,
      err,
    };
  },

  addSoListBegin: () => {
    return {
      type: actions.ADD_SO_LIST_BEGIN,
    };
  },

  addSoListSuccess: data => {
    return {
      type: actions.ADD_SO_LIST_SUCCESS,
      data,
    };
  },

  addSoListError: err => {
    return {
      type: actions.ADD_SO_LIST_ERROR,
      err,
    };
  },

  patchSoListBegin: () => {
    return {
      type: actions.PATCH_SO_LIST_BEGIN,
    };
  },

  patchSoListSuccess: data => {
    return {
      type: actions.PATCH_SO_LIST_SUCCESS,
      data,
    };
  },

  patchSoListError: err => {
    return {
      type: actions.PATCH_SO_LIST_ERROR,
      err,
    };
  },

  downloadDocumentBegin: () => {
    return {
      type: actions.DOWNLOAD_DOCUMENT_BEGIN,
    };
  },

  downloadDocumentSuccess: data => {
    return {
      type: actions.DOWNLOAD_DOCUMENT_SUCCESS,
      data,
    };
  },

  downloadDocumentError: err => {
    return {
      type: actions.DOWNLOAD_DOCUMENT_ERROR,
      err,
    };
  },

  createDocumentRelationBegin: () => {
    return {
      type: actions.CREATE_DOCUMENT_RELATION_BEGIN,
    };
  },

  createDocumentRelationSuccess: data => {
    return {
      type: actions.CREATE_DOCUMENT_RELATION_SUCCESS,
      data,
    };
  },

  createDocumentRelationError: err => {
    return {
      type: actions.CREATE_DOCUMENT_RELATION_ERROR,
      err,
    };
  },

  getDocumentRelationBegin: () => {
    return {
      type: actions.GET_DOCUMENT_RELATION_BEGIN,
    };
  },

  getDocumentRelationSuccess: data => {
    return {
      type: actions.GET_DOCUMENT_RELATION_SUCCESS,
      data,
    };
  },

  getDocumentRelationError: err => {
    return {
      type: actions.GET_DOCUMENT_RELATION_ERROR,
      err,
    };
  },

  deleteDocumentRelationBegin: () => {
    return {
      type: actions.DELETE_DOCUMENT_RELATION_BEGIN,
    };
  },

  deleteDocumentRelationSuccess: data => {
    return {
      type: actions.DELETE_DOCUMENT_RELATION_SUCCESS,
      data,
    };
  },

  deleteDocumentRelationError: err => {
    return {
      type: actions.DELETE_DOCUMENT_RELATION_ERROR,
      err,
    };
  },

  getCurrencyBegin: () => {
    return {
      type: actions.GET_CURRENCY_BEGIN,
    };
  },

  getCurrencySuccess: data => {
    return {
      type: actions.GET_CURRENCY_SUCCESS,
      data,
    };
  },

  getCurrencyError: err => {
    return {
      type: actions.GET_CURRENCY_ERROR,
      err,
    };
  },

  getOriginBegin: () => {
    return {
      type: actions.GET_ORIGIN_BEGIN,
    };
  },

  getOriginSuccess: data => {
    return {
      type: actions.GET_ORIGIN_SUCCESS,
      data,
    };
  },

  getOriginError: err => {
    return {
      type: actions.GET_ORIGIN_ERROR,
      err,
    };
  },

  getPlantBegin: () => {
    return {
      type: actions.GET_PLANT_BEGIN,
    };
  },

  getPlantSuccess: data => {
    return {
      type: actions.GET_PLANT_SUCCESS,
      data,
    };
  },

  getPlantError: err => {
    return {
      type: actions.GET_PLANT_ERROR,
      err,
    };
  },

  getProductCategoryBegin: () => {
    return {
      type: actions.GET_PRODUCT_CATEGORY_BEGIN,
    };
  },

  getProductCategorySuccess: data => {
    return {
      type: actions.GET_PRODUCT_CATEGORY_SUCCESS,
      data,
    };
  },

  getProductCategoryError: err => {
    return {
      type: actions.GET_PRODUCT_CATEGORY_ERROR,
      err,
    };
  },

  getMeasurementUnitBegin: () => {
    return {
      type: actions.GET_MEASUREMENT_UNIT_BEGIN,
    };
  },

  getMeasurementUnitSuccess: data => {
    return {
      type: actions.GET_MEASUREMENT_UNIT_SUCCESS,
      data,
    };
  },

  getMeasurementUnitError: err => {
    return {
      type: actions.GET_MEASUREMENT_UNIT_ERROR,
      err,
    };
  },

  getDeliveryBegin: () => {
    return {
      type: actions.GET_DELIVERY_BEGIN,
    };
  },

  getDeliverySuccess: data => {
    return {
      type: actions.GET_DELIVERY_SUCCESS,
      data,
    };
  },

  getDeliveryError: err => {
    return {
      type: actions.GET_DELIVERY_ERROR,
      err,
    };
  },

  getTransportBegin: () => {
    return {
      type: actions.GET_TRANSPORT_BEGIN,
    };
  },

  getTransportSuccess: data => {
    return {
      type: actions.GET_TRANSPORT_SUCCESS,
      data,
    };
  },

  getTransportError: err => {
    return {
      type: actions.GET_TRANSPORT_ERROR,
      err,
    };
  },

  getGtipDocumentTypeBegin: () => {
    return {
      type: actions.GET_GTIP_DOCUMENT_TYPE_BEGIN,
    };
  },

  getGtipDocumentTypeSuccess: data => {
    return {
      type: actions.GET_GTIP_DOCUMENT_TYPE_SUCCESS,
      data,
    };
  },

  getGtipDocumentTypeError: err => {
    return {
      type: actions.GET_GTIP_DOCUMENT_TYPE_ERROR,
      err,
    };
  },

  getProductDocumentControlBegin: () => {
    return {
      type: actions.GET_PRODUCT_DOCUMENT_CONTROL_BEGIN,
    };
  },

  getProductDocumentControlSuccess: data => {
    return {
      type: actions.GET_PRODUCT_DOCUMENT_CONTROL_SUCCESS,
      data,
    };
  },

  getProductDocumentControlError: err => {
    return {
      type: actions.GET_PRODUCT_DOCUMENT_CONTROL_ERROR,
      err,
    };
  },

  addOrUpdateProductDocumentControlBegin: () => {
    return {
      type: actions.ADD_OR_UPDATE_PRODUCT_DOCUMENT_CONTROL_BEGIN,
    };
  },

  addOrUpdateProductDocumentControlSuccess: data => {
    return {
      type: actions.ADD_OR_UPDATE_PRODUCT_DOCUMENT_CONTROL_SUCCESS,
      data,
    };
  },

  addOrUpdateProductDocumentControlError: err => {
    return {
      type: actions.ADD_OR_UPDATE_PRODUCT_DOCUMENT_CONTROL_ERROR,
      err,
    };
  },

  addOrUpdateGtipDocumentTypeBegin: () => {
    return {
      type: actions.ADD_OR_UPDATE_GTIP_DOCUMENT_TYPE_BEGIN,
    };
  },

  addOrUpdateGtipDocumentTypeSuccess: data => {
    return {
      type: actions.ADD_OR_UPDATE_GTIP_DOCUMENT_TYPE_SUCCESS,
      data,
    };
  },

  addOrUpdateGtipDocumentTypeError: err => {
    return {
      type: actions.ADD_OR_UPDATE_GTIP_DOCUMENT_TYPE_ERROR,
      err,
    };
  },

  updateInvoicesDetailBegin: () => {
    return {
      type: actions.UPDATE_INVOICES_DETAIL_BEGIN,
    };
  },

  updateInvoicesDetailSuccess: data => {
    return {
      type: actions.UPDATE_INVOICES_DETAIL_SUCCESS,
      data,
    };
  },

  updateInvoicesDetailError: err => {
    return {
      type: actions.UPDATE_INVOICES_DETAIL_ERROR,
      err,
    };
  },

  getWarehouseStockBegin: () => {
    return {
      type: actions.GET_WAREHOUSE_STOCK_BEGIN,
    };
  },

  getWarehouseStockSuccess: data => {
    return {
      type: actions.GET_WAREHOUSE_STOCK_SUCCESS,
      data,
    };
  },

  getWarehouseStockError: err => {
    return {
      type: actions.GET_WAREHOUSE_STOCK_ERROR,
      err,
    };
  },

  createDemandBegin: () => {
    return {
      type: actions.CREATE_DEMAND_BEGIN,
    };
  },

  createDemandSuccess: data => {
    return {
      type: actions.CREATE_DEMAND_SUCCESS,
      data,
    };
  },

  createDemandError: err => {
    return {
      type: actions.CREATE_DEMAND_ERROR,
      err,
    };
  },

  deleteDemandBegin: () => {
    return {
      type: actions.DELETE_DEMAND_BEGIN,
    };
  },

  deleteDemandSuccess: data => {
    return {
      type: actions.DELETE_DEMAND_SUCCESS,
      data,
    };
  },

  deleteDemandError: err => {
    return {
      type: actions.DELETE_DEMAND_ERROR,
      err,
    };
  },

  getDemandBegin: () => {
    return {
      type: actions.GET_DEMAND_BEGIN,
    };
  },

  getDemandSuccess: data => {
    return {
      type: actions.GET_DEMAND_SUCCESS,
      data,
    };
  },

  getDemandError: err => {
    return {
      type: actions.GET_DEMAND_ERROR,
      err,
    };
  },

  updateDemandEvrimBegin: () => {
    return {
      type: actions.UPDATE_DEMAND_EVRIM_BEGIN,
    };
  },

  updateDemandEvrimSuccess: data => {
    return {
      type: actions.UPDATE_DEMAND_EVRIM_SUCCESS,
      data,
    };
  },

  updateDemandEvrimError: err => {
    return {
      type: actions.UPDATE_DEMAND_EVRIM_ERROR,
      err,
    };
  },

  getDemandInfoBegin: () => {
    return {
      type: actions.GET_DEMAND_INFO_BEGIN,
    };
  },

  getDemandInfoSuccess: data => {
    return {
      type: actions.GET_DEMAND_INFO_SUCCESS,
      data,
    };
  },

  getDemandInfoError: err => {
    return {
      type: actions.GET_DEMAND_INFO_ERROR,
      err,
    };
  },

  uploadDemandBegin: () => {
    return {
      type: actions.UPLOAD_DEMAND_BEGIN,
    };
  },

  uploadDemandSuccess: data => {
    return {
      type: actions.UPLOAD_DEMAND_SUCCESS,
      data,
    };
  },

  uploadDemandError: err => {
    return {
      type: actions.UPLOAD_DEMAND_ERROR,
      err,
    };
  },

  createDemandByExcelBegin: () => {
    return {
      type: actions.CREATE_DEMAND_EXCEL_BEGIN,
    };
  },

  createDemandByExcelSuccess: data => {
    return {
      type: actions.CREATE_DEMAND_EXCEL_SUCCESS,
      data,
    };
  },

  createDemandByExcelError: err => {
    return {
      type: actions.CREATE_DEMAND_EXCEL_ERROR,
      err,
    };
  },

  getAntrepoBeyannameBegin: () => {
    return {
      type: actions.GET_ANTREPO_BEYANNAME_BEGIN,
    };
  },

  getAntrepoBeyannameSuccess: data => {
    return {
      type: actions.GET_ANTREPO_BEYANNAME_SUCCESS,
      data,
    };
  },

  getAntrepoBeyannameError: err => {
    return {
      type: actions.GET_ANTREPO_BEYANNAME_ERROR,
      err,
    };
  },

  getAllShipmentDeclarationsBegin: () => {
    return {
      type: actions.GET_ALL_SHIPMENT_DECLARATIONS_BEGIN,
    };
  },

  getAllShipmentDeclarationsSuccess: data => {
    return {
      type: actions.GET_ALL_SHIPMENT_DECLARATIONS_SUCCESS,
      data,
    };
  },

  getAllShipmentDeclarationsError: err => {
    return {
      type: actions.GET_ALL_SHIPMENT_DECLARATIONS_ERROR,
      err,
    };
  },

  getIthalatBeyannameBegin: () => {
    return {
      type: actions.GET_ITHALAT_BEYANNAME_BEGIN,
    };
  },

  getIthalatBeyannameSuccess: data => {
    return {
      type: actions.GET_ITHALAT_BEYANNAME_SUCCESS,
      data,
    };
  },

  getIthalatBeyannameError: err => {
    return {
      type: actions.GET_ITHALAT_BEYANNAME_ERROR,
      err,
    };
  },

  getAntrepoStokBegin: () => {
    return {
      type: actions.GET_ANTREPO_STOK_BEGIN,
    };
  },

  getAntrepoStokSuccess: data => {
    return {
      type: actions.GET_ANTREPO_STOK_SUCCESS,
      data,
    };
  },

  getAntrepoStokError: err => {
    return {
      type: actions.GET_ANTREPO_STOK_ERROR,
      err,
    };
  },

  getBeyannameIsTakipBegin: () => {
    return {
      type: actions.GET_BEYANNAME_IS_TAKIP_DETAY_BEGIN,
    };
  },

  getBeyannameIsTakipSuccess: data => {
    return {
      type: actions.GET_BEYANNAME_IS_TAKIP_DETAY_SUCCESS,
      data,
    };
  },

  getBeyannameIsTakipError: err => {
    return {
      type: actions.GET_BEYANNAME_IS_TAKIP_DETAY_ERROR,
      err,
    };
  },

  getBeyannameDetayBegin: () => {
    return {
      type: actions.GET_BEYANNAME_DETAY_SORGULA_BEGIN,
    };
  },

  getBeyannameDetaySuccess: data => {
    return {
      type: actions.GET_BEYANNAME_DETAY_SORGULA_SUCCESS,
      data,
    };
  },

  getBeyannameDetayError: err => {
    return {
      type: actions.GET_BEYANNAME_DETAY_SORGULA_ERROR,
      err,
    };
  },

  patchSoNoBegin: () => {
    return {
      type: actions.PATCH_SO_NO_BEGIN,
    };
  },

  patchSoNoSuccess: data => {
    return {
      type: actions.PATCH_SO_NO_SUCCESS,
      data,
    };
  },

  patchSoNoError: err => {
    return {
      type: actions.PATCH_SO_NO_ERROR,
      err,
    };
  },

  resetErrorMessage: () => {
    return {
      type: actions.RESET_ERROR_MESSAGE,
    };
  },

  getGitData: data => {
    return {
      type: actions.GET_GIT_DATA,
      payload: data,
    };
  },

  getGitDataError: err => {
    return {
      type: actions.GET_ALL_DATA_ERROR,
      err,
    };
  },

  getGitOneDocumentData: data => {
    return {
      type: actions.GET_GIT_ONE_DOCUMENT_DATA,
      payload: data,
    };
  },

  downloadGitReport: data => {
    return {
      type: actions.DOWNLOAD_GİT_REPORT,
      payload: data,
    };
  },

  downloadGitReportSuccess: data => {
    return {
      type: actions.DOWNLOAD_GİT_REPORT_SUCCESS,
      payload: data,
    };
  },

  downloadGitReportFailed: err => {
    return {
      type: actions.DOWNLOAD_GİT_REPORT_FAİLED,
      err,
    };
  },

  getGitInvoicesData: data => {
    return {
      type: actions.GET_INVOICES_DATA,
      invoices: data,
    };
  },

  getGitInvoicesDataError: err => {
    return {
      type: actions.GET_INVOICES_DATA_ERROR,
      err,
    };
  },

  getEvrimArchiveBegin: () => {
    return {
      type: actions.GET_EVRIM_ARCHIVE_BEGIN,
    };
  },

  getEvrimArchiveSuccess: data => {
    return {
      type: actions.GET_EVRIM_ARCHIVE_SUCCESS,
      data,
    };
  },

  getEvrimArchiveError: err => {
    return {
      type: actions.GET_EVRIM_ARCHIVE_ERROR,
      err,
    };
  },

  getEvrimArchiveDocumentBegin: () => {
    return {
      type: actions.GET_EVRIM_ARCHIVE_DOCUMENT_BEGIN,
    };
  },

  getEvrimArchiveDocumentSuccess: data => {
    return {
      type: actions.GET_EVRIM_ARCHIVE_DOCUMENT_SUCCESS,
      data,
    };
  },

  getEvrimArchiveDocumentError: err => {
    return {
      type: actions.GET_EVRIM_ARCHIVE_DOCUMENT_ERROR,
      err,
    };
  },

  getGenelRaporlarBegin: () => {
    return {
      type: actions.GET_GENEL_RAPORLAR_BEGIN,
    };
  },

  getGenelRaporlarSuccess: data => {
    return {
      type: actions.GET_GENEL_RAPORLAR_SUCCESS,
      data,
    };
  },

  getGenelRaporlarError: err => {
    return {
      type: actions.GET_GENEL_RAPORLAR_ERROR,
      err,
    };
  },
  getCompanyBegin: () => {
    return {
      type: actions.GET_COMPANY_BEGIN,
    };
  },

  getCompanySuccess: data => {
    return {
      type: actions.GET_COMPANY_SUCCESS,
      data,
    };
  },

  getCompanyError: err => {
    return {
      type: actions.GET_COMPANY_ERROR,
      err,
    };
  },

  getWarehouseDefinitionsBegin: () => {
    return {
      type: actions.GET_WAREHOUSE_DEFINITIONS_BEGIN,
    };
  },

  getWarehouseDefinitionsSuccess: data => {
    return {
      type: actions.GET_WAREHOUSE_DEFINITIONS_SUCCESS,
      data,
    };
  },

  getWarehouseDefinitionsError: err => {
    return {
      type: actions.GET_WAREHOUSE_DEFINITIONS_ERROR,
      err,
    };
  },

  getAgentDefinitionsBegin: () => {
    return {
      type: actions.GET_AGENT_DEFINITIONS_BEGIN,
    };
  },

  getAgentDefinitionsSuccess: data => {
    return {
      type: actions.GET_AGENT_DEFINITIONS_SUCCESS,
      data,
    };
  },

  getAgentDefinitionsError: err => {
    return {
      type: actions.GET_AGENT_DEFINITIONS_ERROR,
      err,
    };
  },

  getProductBegin: () => {
    return {
      type: actions.GET_PRODUCT_BEGIN,
    };
  },

  getProductSuccess: (data, page) => {
    return {
      type: actions.GET_PRODUCT_SUCCESS,
      data,
      page,
    };
  },

  getProductError: err => {
    return {
      type: actions.GET_PRODUCT_ERROR,
      err,
    };
  },

  getProductAllBegin: () => {
    return {
      type: actions.GET_PRODUCT_ALL_BEGIN,
    };
  },

  getProductAllSuccess: data => {
    return {
      type: actions.GET_PRODUCT_ALL_SUCCESS,
      data,
    };
  },

  getProductAllError: err => {
    return {
      type: actions.GET_PRODUCT_ALL_ERROR,
      err,
    };
  },
  getGenelRaporlarDetayBegin: () => {
    return {
      type: actions.GET_GENEL_RAPORLAR_DETAY_BEGIN,
    };
  },

  getGenelRaporlarDetaySuccess: data => {
    return {
      type: actions.GET_GENEL_RAPORLAR_DETAY_SUCCESS,
      data,
    };
  },

  updateCompanyBegin: () => {
    return {
      type: actions.UPDATE_COMPANY_BEGIN,
    };
  },

  updateCompanySuccess: data => {
    return {
      type: actions.UPDATE_COMPANY_SUCCESS,
      data,
    };
  },

  updateCompanyError: err => {
    return {
      type: actions.UPDATE_COMPANY_ERROR,
      err,
    };
  },

  updateWarehouseDefinitionsBegin: () => {
    return {
      type: actions.UPDATE_WAREHOUSE_DEFINITIONS_BEGIN,
    };
  },

  updateWarehouseDefinitionsSuccess: data => {
    return {
      type: actions.UPDATE_WAREHOUSE_DEFINITIONS_SUCCESS,
      data,
    };
  },

  updateWarehouseDefinitionsError: err => {
    return {
      type: actions.UPDATE_WAREHOUSE_DEFINITIONS_ERROR,
      err,
    };
  },

  updateAgentDefinitionsBegin: () => {
    return {
      type: actions.UPDATE_AGENT_DEFINITIONS_BEGIN,
    };
  },

  updateAgentDefinitionsSuccess: data => {
    return {
      type: actions.UPDATE_AGENT_DEFINITIONS_SUCCESS,
      data,
    };
  },

  updateAgentDefinitionsError: err => {
    return {
      type: actions.UPDATE_AGENT_DEFINITIONS_ERROR,
      err,
    };
  },
  updateWarehouseAgentDefinitionsError: err => {
    return {
      type: actions.UPDATE_AGENT_DEFINITIONS_ERROR,
      err,
    };
  },

  getGenelRaporlarDetayError: err => {
    return {
      type: actions.GET_GENEL_RAPORLAR_DETAY_ERROR,
      err,
    };
  },

  getWantedGenelRaporDetayBegin: () => {
    return {
      type: actions.GET_GENEL_RAPOR_DETAYI_BEGIN,
    };
  },

  getWantedGenelRaporDetaySuccess: data => {
    return {
      type: actions.GET_GENEL_RAPOR_DETAYI_SUCCESS,
      data,
    };
  },

  getWantedGenelRaporDetayError: err => {
    return {
      type: actions.GET_GENEL_RAPOR_DETAYI_ERROR,
      err,
    };
  },

  getTalepListesiBegin: () => {
    return {
      type: actions.GET_TALEP_LISTESI_BEGIN,
    };
  },

  getTalepListesiSuccess: data => {
    return {
      type: actions.GET_TALEP_LISTESI_SUCCESS,
      data,
    };
  },

  getTalepListesiError: err => {
    return {
      type: actions.GET_TALEP_LISTESI_ERROR,
      err,
    };
  },

  deleteTalepBegin: () => {
    return {
      type: actions.DELETE_TALEP_BEGIN,
    };
  },

  deleteTalepSuccess: data => {
    return {
      type: actions.DELETE_DEMAND_SUCCESS,
      data,
    };
  },

  deleteTalepError: err => {
    return {
      type: actions.DELETE_TALEP_ERROR,
      err,
    };
  },

  getGtipListBegin: () => {
    return {
      type: actions.GET_GTIP_LIST_BEGIN,
    };
  },

  getGtipListSuccess: data => {
    return {
      type: actions.GET_GTIP_LIST_SUCCESS,
      data,
    };
  },

  getGtipListError: err => {
    return {
      type: actions.GET_GTIP_LIST_ERROR,
      err,
    };
  },

  addGtipBegin: () => {
    return {
      type: actions.ADD_GTIP_BEGIN,
    };
  },

  addGtipSuccess: data => {
    return {
      type: actions.ADD_GTIP_SUCCESS,
      data,
    };
  },

  addGtipError: err => {
    return {
      type: actions.ADD_GTIP_ERROR,
      err,
    };
  },

  getGtipDetayBegin: () => {
    return {
      type: actions.GET_GTIP_DETAY_BEGIN,
    };
  },

  getGtipDetaySuccess: data => {
    return {
      type: actions.GET_GTIP_DETAY_SUCCESS,
      data,
    };
  },

  getGtipDetayError: err => {
    return {
      type: actions.GET_GTIP_DETAY_ERROR,
      err,
    };
  },

  addGtipDetayBegin: () => {
    return {
      type: actions.ADD_GTIP_DETAY_BEGIN,
    };
  },

  addGtipDetaySuccess: data => {
    return {
      type: actions.ADD_GTIP_DETAY_SUCCESS,
      data,
    };
  },

  addGtipDetayError: err => {
    return {
      type: actions.ADD_GTIP_DETAY_ERROR,
      err,
    };
  },

  getBeyanOnizlemeBegin: () => {
    return {
      type: actions.GET_BEYAN_ONIZLEME_BEGIN,
    };
  },

  getBeyanOnizlemeSuccess: data => {
    return {
      type: actions.GET_BEYAN_ONIZLEME_SUCCESS,
      data,
    };
  },

  getBeyanOnizlemeError: err => {
    return {
      type: actions.GET_BEYAN_ONIZLEME_ERROR,
      err,
    };
  },

  getPernordAntrepoBeyannameOlusturBegin: () => {
    return {
      type: actions.GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_BEGIN,
    };
  },

  getPernordAntrepoBeyannameOlusturSuccess: data => {
    return {
      type: actions.GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_SUCCESS,
      data,
    };
  },

  getPernordAntrepoBeyannameOlusturError: err => {
    return {
      type: actions.GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_ERROR,
      err,
    };
  },

  getOldSoListBegin: () => {
    return {
      type: actions.GET_OLD_SO_LIST_BEGIN,
    };
  },

  getOldSoListSuccess: data => {
    return {
      type: actions.GET_OLD_SO_LIST_SUCCESS,
      data,
    };
  },

  getDemandDocumentInfoBegin: () => {
    return {
      type: actions.GET_DEMAND_DOCUMENT_INFO_BEGIN,
    };
  },

  getDemandDocumentInfoSuccess: data => {
    return {
      type: actions.GET_DEMAND_DOCUMENT_INFO_SUCCESS,
      data,
    };
  },

  getOldSoListError: err => {
    return {
      type: actions.GET_OLD_SO_LIST_ERROR,
      err,
    };
  },

  putOldSoBegin: () => {
    return {
      type: actions.PUT_OLD_SO_BEGIN,
    };
  },

  putOldSoSuccess: data => {
    return {
      type: actions.PUT_OLD_SO_SUCCESS,
      data,
    };
  },

  putOldSoError: err => {
    return {
      type: actions.PUT_OLD_SO_ERROR,
      err,
    };
  },

  getDemandDocumentInfoError: err => {
    return {
      type: actions.GET_DEMAND_DOCUMENT_INFO_ERROR,
      err,
    };
  },

  addDocumentTrackingBegin: () => {
    return {
      type: actions.ADD_DOCUMENT_TRACKING_BEGIN,
    };
  },

  addDocumentTrackingSuccess: data => {
    return {
      type: actions.ADD_DOCUMENT_TRACKING_SUCCESS,
      data,
    };
  },

  addDocumentTrackingError: err => {
    return {
      type: actions.ADD_DOCUMENT_TRACKING_ERROR,
      err,
    };
  },

  deleteDocumentTrackingBegin: () => {
    return {
      type: actions.DELETE_DOCUMENT_TRACKING_BEGIN,
    };
  },

  deleteDocumentTrackingSuccess: data => {
    return {
      type: actions.DELETE_DOCUMENT_TRACKING_SUCCESS,
      data,
    };
  },

  deleteDocumentTrackingError: err => {
    return {
      type: actions.DELETE_DOCUMENT_TRACKING_ERROR,
      err,
    };
  },

  updateDocumentTrackingBegin: () => {
    return {
      type: actions.UPDATE_DOCUMENT_TRACKING_BEGIN,
    };
  },

  updateDocumentTrackingSuccess: data => {
    return {
      type: actions.UPDATE_DOCUMENT_TRACKING_SUCCESS,
      data,
    };
  },

  updateDocumentTrackingError: err => {
    return {
      type: actions.UPDATE_DOCUMENT_TRACKING_ERROR,
      err,
    };
  },

  getDocumentTrackingBegin: () => {
    return {
      type: actions.GET_DOCUMENT_TRACKING_BEGIN,
    };
  },

  getDocumentTrackingSuccess: data => {
    return {
      type: actions.GET_DOCUMENT_TRACKING_SUCCESS,
      data,
    };
  },

  getDocumentTrackingError: err => {
    return {
      type: actions.GET_DOCUMENT_TRACKING_ERROR,
      err,
    };
  },
  getDocumentDetailBegin: () => {
    return {
      type: actions.GET_DOCUMENT_DETAIL_BEGIN,
    };
  },

  getDocumentDetailSuccess: data => {
    return {
      type: actions.GET_DOCUMENT_DETAIL_SUCCESS,
      data,
    };
  },

  getDocumentDetailError: err => {
    return {
      type: actions.GET_DOCUMENT_DETAIL_ERROR,
      err,
    };
  },
  addDocumentProductBegin: () => {
    return {
      type: actions.ADD_DOCUMENT_PRODUCT_BEGIN,
    };
  },
  addDocumentProductSuccess: data => {
    return {
      type: actions.ADD_DOCUMENT_PRODUCT_SUCCESS,
      data,
    };
  },
  addDocumentProductError: err => {
    return {
      type: actions.ADD_DOCUMENT_PRODUCT_ERROR,
      err,
    };
  },
  updateDocumentProductBegin: () => {
    return {
      type: actions.UPDATE_DOCUMENT_PRODUCT_BEGIN,
    };
  },
  updateDocumentProductSuccess: data => {
    return {
      type: actions.UPDATE_DOCUMENT_PRODUCT_SUCCESS,
      data,
    };
  },
  updateDocumentProductError: err => {
    return {
      type: actions.UPDATE_DOCUMENT_PRODUCT_ERROR,
      err,
    };
  },
  getDocumentProductBegin: () => {
    return {
      type: actions.GET_DOCUMENT_PRODUCT_BEGIN,
    };
  },
  getDocumentProductSuccess: data => {
    return {
      type: actions.GET_DOCUMENT_PRODUCT_SUCCESS,
      data,
    };
  },
  getDocumentProductError: () => {
    return {
      type: actions.GET_DOCUMENT_PRODUCT_ERROR,
    };
  },
  addOrderBegin: () => {
    return {
      type: actions.ADD_ORDER_BEGIN,
    };
  },
  addOrderSuccess: data => {
    return {
      type: actions.ADD_ORDER_SUCCESS,
      data,
    };
  },
  addOrderError: err => {
    return {
      type: actions.ADD_ORDER_ERROR,
      err,
    };
  },
  updateOrderBegin: () => {
    return {
      type: actions.UPDATE_ORDER_BEGIN,
    };
  },
  updateOrderSuccess: data => {
    return {
      type: actions.UPDATE_ORDER_SUCCESS,
      data,
    };
  },
  updateOrderError: err => {
    return {
      type: actions.UPDATE_ORDER_ERROR,
      err,
    };
  },
  getOrderBegin: () => {
    return {
      type: actions.GET_ORDER_BEGIN,
    };
  },
  getOrderSuccess: (data, page) => {
    return {
      type: actions.GET_ORDER_SUCCESS,
      data,
      page,
    };
  },
  getOrderError: err => {
    return {
      type: actions.GET_ORDER_ERROR,
      err,
    };
  },

  getGeciciBegin: () => {
    return {
      type: actions.GET_GECICI_BEGIN,
    };
  },
  getGeciciSuccess: (data, page) => {
    return {
      type: actions.GET_GECICI_SUCCESS,
      data,
      page,
    };
  },
  getGeciciError: err => {
    return {
      type: actions.GET_GECICI_ERROR,
      err,
    };
  },
  addOrderNotesBegin: () => {
    return {
      type: actions.ADD_ORDER_NOTES_BEGIN,
    };
  },
  addOrderNotesSuccess: data => {
    return {
      type: actions.ADD_ORDER_NOTES_SUCCESS,
      data,
    };
  },
  addOrderNotesError: err => {
    return {
      type: actions.ADD_ORDER_NOTES_ERROR,
      err,
    };
  },
  getOrderNotesBegin: () => {
    return {
      type: actions.GET_ORDER_NOTES_BEGIN,
    };
  },
  getOrderNotesSuccess: data => {
    return {
      type: actions.GET_ORDER_NOTES_SUCCESS,
      data,
    };
  },
  getOrderNotesError: () => {
    return {
      type: actions.GET_ORDER_NOTES_ERROR,
    };
  },
  addOrderDeclarationBegin: () => {
    return {
      type: actions.ADD_ORDER_DECLARATION_BEGIN,
    };
  },
  addOrderDeclarationSuccess: data => {
    return {
      type: actions.ADD_ORDER_DECLARATION_SUCCESS,
      data,
    };
  },
  addOrderDeclarationError: err => {
    return {
      type: actions.ADD_ORDER_DECLARATION_ERROR,
      err,
    };
  },
  getOrderDeclarationBegin: () => {
    return {
      type: actions.GET_ORDER_DECLARATION_BEGIN,
    };
  },
  getOrderDeclarationSuccess: data => {
    return {
      type: actions.GET_ORDER_DECLARATION_SUCCESS,
      data,
    };
  },
  getOrderDeclarationError: () => {
    return {
      type: actions.GET_ORDER_DECLARATION_ERROR,
    };
  },
  getDocumentRezervedBegin: () => {
    return {
      type: actions.GET_DOCUMENT_REZERVED_BEGIN,
    };
  },
  getDocumentRezervedSuccess: data => {
    return {
      type: actions.GET_DOCUMENT_REZERVED_SUCCESS,
      data,
    };
  },
  getDocumentRezervedError: () => {
    return {
      type: actions.GET_DOCUMENT_REZERVED_ERROR,
    };
  },
  orderRequiredDocumentsBegin: () => {
    return {
      type: actions.ORDER_REQUIRED_DOCUMENTS_BEGIN,
    };
  },
  orderRequiredDocumentsSuccess: data => {
    return {
      type: actions.ORDER_REQUIRED_DOCUMENTS_SUCCESS,
      data,
    };
  },
  orderRequiredDocumentsError: () => {
    return {
      type: actions.ORDER_REQUIRED_DOCUMENTS_ERROR,
    };
  },
  documentTrackingSearchBegin: () => {
    return {
      type: actions.DOCUMENT_TRACKING_SEARCH_BEGIN,
    };
  },
  documentTrackingSearchSuccess: data => {
    return {
      type: actions.DOCUMENT_TRACKING_SEARCH_SUCCESS,
      data,
    };
  },
  documentTrackingSearchError: () => {
    return {
      type: actions.DOCUMENT_TRACKING_SEARCH_ERROR,
    };
  },
  documentRezerveSaveBegin: () => {
    return {
      type: actions.DOCUMENT_REZERVE_SAVE_BEGIN,
    };
  },
  documentRezerveSaveSuccess: data => {
    return {
      type: actions.DOCUMENT_REZERVE_SAVE_SUCCESS,
      data,
    };
  },
  documentRezerveSaveError: () => {
    return {
      type: actions.DOCUMENT_REZERVE_SAVE_ERROR,
    };
  },
  documentRezerveDeleteBegin: () => {
    return {
      type: actions.DOCUMENT_REZERVE_DELETE_BEGIN,
    };
  },
  documentRezerveDeleteSuccess: data => {
    return {
      type: actions.DOCUMENT_REZERVE_DELETE_SUCCESS,
      data,
    };
  },
  documentRezerveDeleteError: () => {
    return {
      type: actions.DOCUMENT_REZERVE_DELETE_ERROR,
    };
  },
  orderAddExcelBegin: () => {
    return {
      type: actions.ORDER_ADD_EXCEL_BEGIN,
    };
  },

  orderAddExcelSuccess: data => {
    return {
      type: actions.ORDER_ADD_EXCEL_SUCCESS,
      data,
    };
  },

  orderAddExcelError: err => {
    return {
      type: actions.ORDER_ADD_EXCEL_ERROR,
      err,
    };
  },

  pirAddExcelBegin: () => {
    return {
      type: actions.PIR_ADD_EXCEL_BEGIN,
    };
  },

  pirAddExcelSuccess: data => {
    return {
      type: actions.PIR_ADD_EXCEL_SUCCESS,
      data,
    };
  },

  pirAddExcelError: err => {
    return {
      type: actions.PIR_ADD_EXCEL_ERROR,
      err,
    };
  },

  addPRExcelBegin: () => {
    return {
      type: actions.ADD_PR_EXCEL_BEGIN,
    };
  },

  addPRExcelSuccess: data => {
    return {
      type: actions.ADD_PR_EXCEL_SUCCESS,
      data,
    };
  },

  addPRExcelError: err => {
    return {
      type: actions.ADD_PR_EXCEL_ERROR,
      err,
    };
  },

  getOrderLogsBegin: () => {
    return {
      type: actions.GET_ORDER_LOGS_BEGIN,
    };
  },
  getOrderLogsSuccess: data => {
    return {
      type: actions.GET_ORDER_LOGS_SUCCESS,
      data,
    };
  },
  getOrderLogsError: () => {
    return {
      type: actions.GET_ORDER_LOGS_ERROR,
    };
  },
  getGeciciIthalatBegin: () => {
    return {
      type: actions.GET_GECICI_ITHALAT_BEGIN,
    };
  },
  getGeciciIthalatSuccess: data => {
    return {
      type: actions.GET_GECICI_ITHALAT_SUCCESS,
      data,
    };
  },
  getGeciciIthalatError: () => {
    return {
      type: actions.GET_GECICI_ITHALAT_ERROR,
    };
  },

  updateGeciciIthalatBegin: () => {
    return {
      type: actions.UPDATE_GECICI_ITHALAT_BEGIN,
    };
  },
  updateGeciciIthalatSuccess: data => {
    return {
      type: actions.UPDATE_GECICI_ITHALAT_SUCCESS,
      data,
    };
  },
  updateGeciciIthalatError: () => {
    return {
      type: actions.UPDATE_GECICI_ITHALAT_ERROR,
    };
  },

  getProdcutMasterDataBegin: () => {
    return {
      type: actions.GET_PRODUCT_MASTAR_DATA_BEGIN,
    };
  },
  getProdcutMasterDataSuccess: data => {
    return {
      type: actions.GET_PRODUCT_MASTAR_DATA_SUCCESS,
      data,
    };
  },
  getProdcutMasterDataError: err => {
    return {
      type: actions.GET_PRODUCT_MASTER_DATA_ERROR,
      err,
    };
  },
  updateProductMasterDataBegin: () => {
    return {
      type: actions.UPDATE_PRODUCT_MASTER_DATA_BEGIN,
    };
  },
  updateProductMasterDataSuccess: data => {
    return {
      type: actions.UPDATE_PRODUCT_MASTER_DATA_SUCCESS,
      data,
    };
  },
  updateProductMasterDataError: err => {
    return {
      type: actions.UPDATE_PRODUCT_MASTER_DATA_ERROR,
      err,
    };
  },
  addProductMasterDataBegin: () => {
    return {
      type: actions.ADD_PRODUCT_MASTER_DATA_BEGIN,
    };
  },
  addProductMasterDataSuccess: data => {
    return {
      type: actions.ADD_PRODUCT_MASTER_DATA_SUCCESS,
      data,
    };
  },
  addProductMasterDataError: err => {
    return {
      type: actions.ADD_PRODUCT_MASTER_DATA_ERROR,
      err,
    };
  },

  getOriginalDocumentTrackingListBegin: () => {
    return {
      type: actions.GET_ORIGINAL_DOCUMENT_TRACKING_LIST_BEGIN,
    };
  },

  getOriginalDocumentTrackingListSuccess: data => {
    return {
      type: actions.GET_ORIGINAL_DOCUMENT_TRACKING_LIST_SUCCESS,
      data,
    };
  },

  getOriginalDocumentTrackingListError: err => {
    return {
      type: actions.GET_ORIGINAL_DOCUMENT_TRACKING_LIST_ERROR,
      err,
    };
  },

  getOriginalDocumentTrackingByInvoiceNoBegin: () => {
    return {
      type: actions.GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_BEGIN,
    };
  },

  getOriginalDocumentTrackingByInvoiceNoSuccess: data => {
    return {
      type: actions.GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_SUCCESS,
      data,
    };
  },

  getOriginalDocumentTrackingByInvoiceNoError: err => {
    return {
      type: actions.GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_ERROR,
      err,
    };
  },

  addOriginalDocumentTrackingBegin: () => {
    return {
      type: actions.ADD_ORIGINAL_DOCUMENT_TRACKING_BEGIN,
    };
  },

  addOriginalDocumentTrackingSuccess: data => {
    return {
      type: actions.ADD_ORIGINAL_DOCUMENT_TRACKING_SUCCESS,
      data,
    };
  },

  addOriginalDocumentTrackingError: err => {
    return {
      type: actions.ADD_ORIGINAL_DOCUMENT_TRACKING_ERROR,
      err,
    };
  },

  updateOriginalDocumentTrackingBegin: () => {
    return {
      type: actions.UPDATE_ORIGINAL_DOCUMENT_TRACKING_BEGIN,
    };
  },

  updateOriginalDocumentTrackingSuccess: data => {
    return {
      type: actions.UPDATE_ORIGINAL_DOCUMENT_TRACKING_SUCCESS,
      data,
    };
  },

  updateOriginalDocumentTrackingError: err => {
    return {
      type: actions.UPDATE_ORIGINAL_DOCUMENT_TRACKING_ERROR,
      err,
    };
  },

  getOriginalDocumentTypeListBegin: () => {
    return {
      type: actions.GET_ORIGINAL_DOCUMENT_TYPE_LIST_BEGIN,
    };
  },

  getOriginalDocumentTypeListSuccess: data => {
    return {
      type: actions.GET_ORIGINAL_DOCUMENT_TYPE_LIST_SUCCESS,
      data,
    };
  },

  getOriginalDocumentTypeListError: err => {
    return {
      type: actions.GET_ORIGINAL_DOCUMENT_TYPE_LIST_ERROR,
      err,
    };
  },

  addGeciciExportBegin: () => {
    return {
      type: actions.ADD_GECICI_EXPORT_BEGIN,
    };
  },
  addGeciciExportSuccess: data => {
    return {
      type: actions.ADD_GECICI_EXPORT_SUCCESS,
      data,
    };
  },
  addGeciciExportError: err => {
    return {
      type: actions.ADD_GECICI_EXPORT_ERROR,
      err,
    };
  },

  getGeciciExportBegin: () => {
    return {
      type: actions.GET_GECICI_EXPORT_BEGIN,
    };
  },
  getGeciciExportSuccess: data => {
    return {
      type: actions.GET_GECICI_EXPORT_SUCCESS,
      data,
    };
  },
  getGeciciExportError: err => {
    return {
      type: actions.GET_GECICI_EXPORT_ERROR,
      err,
    };
  },
  getOrderAgentInformationBegin: () => {
    return {
      type: actions.GET_ORDER_AGENT_INFORMATION_BEGIN,
    };
  },

  getOrderAgentInformationSuccess: data => {
    return {
      type: actions.GET_ORDER_AGENT_INFORMATION_SUCCESS,
      data,
    };
  },

  getOrderAgentInformationError: err => {
    return {
      type: actions.GET_ORDER_AGENT_INFORMATION_ERROR,
      err,
    };
  },
  getOrderByOrderIdBegin: () => {
    return {
      type: actions.GET_ORDER_BY_ORDER_ID_BEGIN,
    };
  },

  getOrderByOrderIdSuccess: data => {
    return {
      type: actions.GET_ORDER_BY_ORDER_ID_SUCCESS,
      data,
    };
  },

  getOrderByOrderIdError: err => {
    return {
      type: actions.GET_ORDER_BY_ORDER_ID_ERROR,
      err,
    };
  },
  updateOrderAgentBegin: () => {
    return {
      type: actions.UPDATE_ORDER_AGENT_BEGIN,
    };
  },

  updateOrderAgentSuccess: data => {
    return {
      type: actions.UPDATE_ORDER_AGENT_SUCCESS,
      data,
    };
  },

  updateOrderAgentError: err => {
    return {
      type: actions.UPDATE_ORDER_AGENT_ERROR,
      err,
    };
  },

  getWantedProductsBegin: () => {
    return {
      type: actions.GET_WANTED_PRODUCTS_BEGIN,
    };
  },

  getWantedProductsSuccess: (data, page) => {
    return {
      type: actions.GET_WANTED_PRODUCTS_SUCCESS,
      data,
      page,
    };
  },

  getWantedProductsError: err => {
    return {
      type: actions.GET_WANTED_PRODUCTS_ERROR,
      err,
    };
  },

  convertOrdersToExcelReportBegin: () => {
    return {
      type: actions.CONVERT_ORDERS_TO_EXCEL_REPORT_BEGIN,
    };
  },

  convertOrdersToExcelReportSuccess: data => {
    return {
      type: actions.CONVERT_ORDERS_TO_EXCEL_REPORT_SUCCES,
      data,
    };
  },

  convertOrdersToExcelReportError: err => {
    return {
      type: actions.CONVERT_ORDERS_TO_EXCEL_REPORT_ERROR,
      err,
    };
  },

  cezalarToExcelReportBegin: () => {
    return {
      type: actions.CEZALAR_TO_EXCEL_REPORT_BEGIN,
    };
  },

  cezalarToExcelReportSuccess: data => {
    return {
      type: actions.CEZALAR_TO_EXCEL_REPORT_SUCCESS,
      data,
    };
  },

  cezalarToExcelReportError: err => {
    return {
      type: actions.CEZALAR_TO_EXCEL_REPORT_ERROR,
      err,
    };
  },
  getShippingLineBegin: () => {
    return {
      type: actions.GET_SHIPPING_LINE_BEGIN,
    };
  },

  getShippingLineSuccess: data => {
    return {
      type: actions.GET_SHIPPING_LINE_SUCCESS,
      data,
    };
  },

  getShippingLineError: err => {
    return {
      type: actions.GET_SHIPPING_LINE_ERROR,
      err,
    };
  },
  getDahildeIslemeBegin: () => {
    return {
      type: actions.GET_DAHILDE_ISLEME_BEGIN,
    };
  },

  getDahildeIslemeSuccess: data => {
    return {
      type: actions.GET_DAHILDE_ISLEME_SUCCESS,
      data,
    };
  },

  getDahildeIslemeError: err => {
    return {
      type: actions.GET_DAHILDE_ISLEME_ERROR,
      err,
    };
  },

  addCommonNumberBegin: () => {
    return {
      type: actions.ADD_COMMON_NUMBER_BEGIN,
    };
  },

  addCommonNumberSuccess: data => {
    return {
      type: actions.ADD_COMMON_NUMBER_SUCCESS,
      data,
    };
  },

  addCommonNumberError: err => {
    return {
      type: actions.ADD_COMMON_NUMBER_ERROR,
      err,
    };
  },

  updateOrderMultipleBegin: () => {
    return {
      type: actions.UPDATE_ORDER_MULTIPLE_BEGIN,
    };
  },
  updateOrderMultipleSuccess: data => {
    return {
      type: actions.UPDATE_ORDER_MULTIPLE_SUCCESS,
      data,
    };
  },
  updateOrderMultipleError: err => {
    return {
      type: actions.UPDATE_ORDER_MULTIPLE_ERROR,
      err,
    };
  },

  getGroupDetailInProductDetailBegin: () => {
    return {
      type: actions.GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_BEGIN,
    };
  },

  getGroupDetailInProductDetailSuccess: data => {
    return {
      type: actions.GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_SUCCESS,
      data,
    };
  },

  getGroupDetailInProductDetailError: err => {
    return {
      type: actions.GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_ERROR,
      err,
    };
  },

  addOrderMultipleDeclarationBegin: () => {
    return {
      type: actions.ADD_ORDER_MULTIPLE_DECLARATION_BEGIN,
    };
  },
  addOrderMultipleDeclarationSuccess: data => {
    return {
      type: actions.ADD_ORDER_MULTIPLE_DECLARATION_SUCCESS,
      data,
    };
  },
  addOrderMultipleDeclarationError: err => {
    return {
      type: actions.ADD_ORDER_MULTIPLE_DECLARATION_ERROR,
      err,
    };
  },
  belgelerToExcelReportBegin: () => {
    return {
      type: actions.BELGELER_TO_EXCEL_REPORT_BEGIN,
    };
  },
  belgelerToExcelReportSuccess: data => {
    return {
      type: actions.BELGELER_TO_EXCEL_REPORT_SUCCESS,
      data,
    };
  },
  belgelerToExcelReportError: err => {
    return {
      type: actions.BELGELER_TO_EXCEL_REPORT_ERROR,
      err,
    };
  },

  getOrderFilterBegin: () => {
    return {
      type: actions.GET_ORDER_FILTER_BEGIN,
    };
  },
  getOrderFilterSuccess: data => {
    return {
      type: actions.GET_ORDER_FILTER_SUCCESS,
      data,
    };
  },
  getOrderFilterError: err => {
    return {
      type: actions.GET_ORDER_FILTER_ERROR,
      err,
    };
  },
  getFilteringProductsBegin: () => {
    return {
      type: actions.GET_FILTERING_PRODUCTS_BEGIN,
    };
  },

  getFilteringProductsError: err => {
    return {
      type: actions.GET_FILTERING_PRODUCTS_ERROR,
      err,
    };
  },

  getFilteringProductsSuccess: data => {
    return {
      type: actions.GET_FILTERING_PRODUCTS_SUCCESS,
      data,
    };
  },
  addMultipleOrderNotesBegin: () => {
    return {
      type: actions.ADD_MULTIPLE_ORDER_NOTES_BEGIN,
    };
  },
  addMultipleOrderNotesSuccess: data => {
    return {
      type: actions.ADD_MULTIPLE_ORDER_NOTES_SUCCESS,
      data,
    };
  },
  addMultipleOrderNotesError: err => {
    return {
      type: actions.ADD_MULTIPLE_ORDER_NOTES_ERROR,
      err,
    };
  },

  addOrderCreditNoteBegin: () => {
    return {
      type: actions.ADD_ORDER_CREDIT_NOTE_BEGIN,
    };
  },
  addOrderCreditNoteSuccess: data => {
    return {
      type: actions.ADD_ORDER_CREDIT_NOTE_SUCCESS,
      data,
    };
  },
  addOrderCreditNoteError: err => {
    return {
      type: actions.ADD_ORDER_CREDIT_NOTE_ERROR,
      err,
    };
  },

  getEvrimCezaByOrderBegin: () => {
    return {
      type: actions.GET_EVRIM_CEZA_BY_ORDER_BEGIN,
    };
  },
  getEvrimCezaByOrderSuccess: data => {
    return {
      type: actions.GET_EVRIM_CEZA_BY_ORDER_SUCCESS,
      data,
    };
  },
  getEvrimCezaByOrderError: err => {
    return {
      type: actions.GET_EVRIM_CEZA_BY_ORDER_ERROR,
      err,
    };
  },

  getOrderDemurajControlBegin: () => {
    return {
      type: actions.GET_ORDER_DEMURAJ_CONTROL_BEGIN,
    };
  },
  getOrderDemurajControlSuccess: data => {
    return {
      type: actions.GET_ORDER_DEMURAJ_CONTROL_SUCCESS,
      data,
    };
  },
  getOrderDemurajControlError: err => {
    return {
      type: actions.GET_ORDER_DEMURAJ_CONTROL_ERROR,
      err,
    };
  },

  getOrderUrgentChangeBegin: () => {
    return {
      type: actions.GET_ORDER_URGENT_CHANGE_BEGIN,
    };
  },
  getOrderUrgentChangeSuccess: data => {
    return {
      type: actions.GET_ORDER_URGENT_CHANGE_SUCCESS,
      data,
    };
  },
  getOrderUrgentChangeError: err => {
    return {
      type: actions.GET_ORDER_URGENT_CHANGE_ERROR,
      err,
    };
  },

  addOrderMasrafBegin: () => {
    return {
      type: actions.ADD_ORDER_MASRAF_BEGIN,
    };
  },
  addOrderMasrafSuccess: data => {
    return {
      type: actions.ADD_ORDER_MASRAF_SUCCESS,
      data,
    };
  },
  addOrderMasrafError: err => {
    return {
      type: actions.ADD_ORDER_MASRAF_ERROR,
      err,
    };
  },

  getOrderRequiredBegin: () => {
    return {
      type: actions.GET_ORDER_REQUIRED_BEGIN,
    };
  },

  getOrderRequiredSuccess: data => {
    return {
      type: actions.GET_ORDER_REQUIRED_SUCCESS,
      data,
    };
  },

  getOrderRequiredError: () => {
    return {
      type: actions.GET_ORDER_REQUIRED_ERROR,
    };
  },

  addIhbarTakipBegin: () => {
    return {
      type: actions.ADD_IHBAR_TAKIP_BEGIN,
    };
  },

  addIhbarTakipSuccess: data => {
    return {
      type: actions.ADD_IHBAR_TAKIP_SUCCESS,
      data,
    };
  },

  addIhbarTakipError: err => {
    return {
      type: actions.ADD_IHBAR_TAKIP_ERROR,
      err,
    };
  },

  addOrderRequiredBegin: () => {
    return {
      type: actions.ADD_ORDER_REQUIRED_BEGIN,
    };
  },
  addOrderRequiredSuccess: data => {
    return {
      type: actions.ADD_ORDER_REQUIRED_SUCCESS,
      data,
    };
  },
  addOrderRequiredError: err => {
    return {
      type: actions.ADD_ORDER_REQUIRED_ERROR,
      err,
    };
  },

  updateIhbarTakipBegin: () => {
    return {
      type: actions.UPDATE_IHBAR_TAKIP_BEGIN,
    };
  },

  updateIhbarTakipSuccess: data => {
    return {
      type: actions.UPDATE_IHBAR_TAKIP_SUCCESS,
      data,
    };
  },

  updateIhbarTakipError: err => {
    return {
      type: actions.UPDATE_IHBAR_TAKIP_ERROR,
      err,
    };
  },

  deleteOrderRequiredBegin: () => {
    return {
      type: actions.DELETE_ORDER_REQUIRED_BEGIN,
    };
  },
  deleteOrderRequiredSuccess: data => {
    return {
      type: actions.DELETE_ORDER_REQUIRED_SUCCESS,
      data,
    };
  },
  deleteOrderRequiredError: err => {
    return {
      type: actions.DELETE_ORDER_REQUIRED_ERROR,
      err,
    };
  },

  getIhbarTakipBegin: () => {
    return {
      type: actions.GET_IHBAR_TAKIP_BEGIN,
    };
  },

  getIhbarTakipSuccess: data => {
    return {
      type: actions.GET_IHBAR_TAKIP_SUCCESS,
      data,
    };
  },

  getIhbarTakipError: err => {
    return {
      type: actions.GET_IHBAR_TAKIP_ERROR,
      err,
    };
  },

  updateRequiredOrderBegin: () => {
    return {
      type: actions.UPDATE_REQUIRED_ORDER_BEGIN,
    };
  },
  updateRequiredOrderSuccess: data => {
    return {
      type: actions.UPDATE_REQUIRED_ORDER_SUCCESS,
      data,
    };
  },
  updateRequiredOrderError: err => {
    return {
      type: actions.UPDATE_REQUIRED_ORDER_ERROR,
      err,
    };
  },
  deleteOrderDeclarationBegin: () => {
    return {
      type: actions.DELETE_ORDER_DECLARATION_BEGIN,
    };
  },
  deleteOrderDeclarationSuccess: data => {
    return {
      type: actions.DELETE_ORDER_DECLARATION_SUCCESS,
      data,
    };
  },
  deleteOrderDeclarationError: err => {
    return {
      type: actions.DELETE_ORDER_DECLARATION_ERROR,
      err,
    };
  },
  addCompanyBegin: () => {
    return {
      type: actions.ADD_COMPANY_BEGIN,
    };
  },
  addCompanySuccess: data => {
    return {
      type: actions.ADD_COMPANY_SUCCESS,
      data,
    };
  },
  addCompanyError: err => {
    return {
      type: actions.ADD_COMPANY_ERROR,
      err,
    };
  },

  addProductBasicBegin: () => {
    return {
      type: actions.ADD_PRODUCT_BASIC_BEGIN,
    };
  },
  addProductBasicSuccess: data => {
    return {
      type: actions.ADD_PRODUCT_BASIC_SUCCESS,
      data,
    };
  },
  addProductBasicError: err => {
    return {
      type: actions.ADD_PRODUCT_BASIC_ERROR,
      err,
    };
  },

  addAcenteDefinitionsBegin: () => {
    return {
      type: actions.ADD_ACENTE_DEFINITIONS_BEGIN,
    };
  },
  addAcenteDefinitionsSuccess: data => {
    return {
      type: actions.ADD_ACENTE_DEFINITIONS_SUCCESS,
      data,
    };
  },
  addAcenteDefinitionsError: err => {
    return {
      type: actions.ADD_ACENTE_DEFINITIONS_ERROR,
      err,
    };
  },

  addAribaExcelBegin: () => {
    return {
      type: actions.ADD_ARIBA_EXCEL_BEGIN,
    };
  },
  addAribaExcelSuccess: data => {
    return {
      type: actions.ADD_ARIBA_EXCEL_SUCCESS,
      data,
    };
  },
  addAribaExcelError: err => {
    return {
      type: actions.ADD_ARIBA_EXCEL_ERROR,
      err,
    };
  },

  getDonusDepoBegin: () => {
    return {
      type: actions.GET_DONUS_DEPO_BEGIN,
    };
  },
  getDonusDepoSuccess: data => {
    return {
      type: actions.GET_DONUS_DEPO_SUCCESS,
      data,
    };
  },
  getDonusDepoError: err => {
    return {
      type: actions.GET_DONUS_DEPO_ERROR,
      err,
    };
  },

  getMasrafListBegin: () => {
    return {
      type: actions.GET_MASRAF_LIST_BEGIN,
    };
  },
  getMasrafListSuccess: data => {
    return {
      type: actions.GET_MASRAF_LIST_SUCCESS,
      data,
    };
  },
  getMasrafListError: err => {
    return {
      type: actions.GET_MASRAF_LIST_ERROR,
      err,
    };
  },

  getMasrafDetayBegin: () => {
    return {
      type: actions.GET_MASRAF_DETAY_BEGIN,
    };
  },
  getMasrafDetaySuccess: data => {
    return {
      type: actions.GET_MASRAF_DETAY_SUCCESS,
      data,
    };
  },
  getMasrafDetayError: err => {
    return {
      type: actions.GET_MASRAF_DETAY_ERROR,
      err,
    };
  },

  getMasrafTipBegin: () => {
    return {
      type: actions.GET_MASRAF_TIP_BEGIN,
    };
  },
  getMasrafTipSuccess: data => {
    return {
      type: actions.GET_MASRAF_TIP_SUCCESS,
      data,
    };
  },
  getMasrafTipError: err => {
    return {
      type: actions.GET_MASRAF_TIP_ERROR,
      err,
    };
  },

  addMasrafTipSaveBegin: () => {
    return {
      type: actions.ADD_MASRAF_TIP_SAVE_BEGIN,
    };
  },
  addMasrafTipSaveSuccess: data => {
    return {
      type: actions.ADD_MASRAF_TIP_SAVE_SUCCESS,
      data,
    };
  },
  addMasrafTipSaveError: err => {
    return {
      type: actions.ADD_MASRAF_TIP_SAVE_ERROR,
      err,
    };
  },

  addMasrafBeyannameNoBegin: () => {
    return {
      type: actions.ADD_MASRAF_BEYANNAME_NO_BEGIN,
    };
  },
  addMasrafBeyannameNoSuccess: data => {
    return {
      type: actions.ADD_MASRAF_BEYANNAME_NO_SUCCESS,
      data,
    };
  },
  addMasrafBeyannameNoError: err => {
    return {
      type: actions.ADD_MASRAF_BEYANNAME_NO_ERROR,
      err,
    };
  },

  getMasrafBeyannameBegin: () => {
    return {
      type: actions.GET_MASRAF_BEYANNAME_BEGIN,
    };
  },
  getMasrafBeyannameSuccess: data => {
    return {
      type: actions.GET_MASRAF_BEYANNAME_SUCCESS,
      data,
    };
  },
  getMasrafBeyannameError: err => {
    return {
      type: actions.GET_MASRAF_BEYANNAME_ERROR,
      err,
    };
  },

  deleteMasrafBeyannameBegin: () => {
    return {
      type: actions.DELETE_MASRAF_BEYANNAME_BEGIN,
    };
  },
  deleteMasrafBeyannameSuccess: data => {
    return {
      type: actions.DELETE_MASRAF_BEYANNAME_SUCCESS,
      data,
    };
  },
  deleteMasrafBeyannameError: err => {
    return {
      type: actions.DELETE_MASRAF_BEYANNAME_ERROR,
      err,
    };
  },

  deleteMasrafFaturaBegin: () => {
    return {
      type: actions.DELETE_MASRAF_FATURA_BEGIN,
    };
  },
  deleteMasrafFaturaSuccess: data => {
    return {
      type: actions.DELETE_MASRAF_FATURA_SUCCESS,
      data,
    };
  },
  deleteMasrafFaturaError: err => {
    return {
      type: actions.DELETE_MASRAF_FATURA_ERROR,
      err,
    };
  },

  deleteOrderNotesBegin: () => {
    return {
      type: actions.DELETE_ORDER_NOTES_BEGIN,
    };
  },
  deleteOrderNotesSuccess: data => {
    return {
      type: actions.DELETE_ORDER_NOTES_SUCCESS,
      data,
    };
  },
  deleteOrderNotesError: err => {
    return {
      type: actions.DELETE_ORDER_NOTES_ERROR,
      err,
    };
  },

  updateOrderTransportBegin: () => {
    return {
      type: actions.UPDATE_ORDER_TRANSPORT_BEGIN,
    };
  },
  updateOrderTransportSuccess: data => {
    return {
      type: actions.UPDATE_ORDER_TRANSPORT_SUCCESS,
      data,
    };
  },
  updateOrderTransportError: err => {
    return {
      type: actions.UPDATE_ORDER_TRANSPORT_ERROR,
      err,
    };
  },

  orderMasrafExcelBegin: () => {
    return {
      type: actions.ORDER_MASRAF_EXCEL_BEGIN,
    };
  },
  orderMasrafExcelSuccess: data => {
    return {
      type: actions.ORDER_MASRAF_EXCEL_SUCCESS,
      data,
    };
  },
  orderMasrafExcelError: err => {
    return {
      type: actions.ORDER_MASRAF_EXCEL_ERROR,
      err,
    };
  },

  masrafFtpUploadBegin: () => {
    return {
      type: actions.MASRAF_FTP_UPLOAD_BEGIN,
    };
  },

  masrafFtpUploadSuccess: data => {
    return {
      type: actions.MASRAF_FTP_UPLOAD_SUCCESS,
      data,
    };
  },

  masrafFtpUploadError: err => {
    return {
      type: actions.MASRAF_FTP_UPLOAD_ERROR,
      err,
    };
  },

  dekontExcelDownlaodBegin: () => {
    return {
      type: actions.DEKONT_EXCEL_DOWNLOAD_BEGIN,
    };
  },

  dekontExcelDownlaodSuccess: data => {
    return {
      type: actions.DEKONT_EXCEL_DOWNLOAD_SUCCESS,
      data,
    };
  },

  dekontExcelDownlaodError: err => {
    return {
      type: actions.DEKONT_EXCEL_DOWNLOAD_ERROR,
      err,
    };
  },

  sigortaCreateBegin: () => {
    return {
      type: actions.SIGORTA_CREATE_BEGIN,
    };
  },

  sigortaCreateSuccess: data => {
    return {
      type: actions.SIGORTA_CREATE_SUCCESS,
      data,
    };
  },

  sigortaCreateError: err => {
    return {
      type: actions.SIGORTA_CREATE_ERROR,
      err,
    };
  },

  getFirmaEvrimBegin: () => {
    return {
      type: actions.GET_FIRMA_EVRIM_BEGIN,
    };
  },

  getFirmaEvrimSuccess: data => {
    return {
      type: actions.GET_FIRMA_EVRIM_SUCCESS,
      data,
    };
  },

  getFirmaEvrimError: err => {
    return {
      type: actions.GET_FIRMA_EVRIM_ERROR,
      err,
    };
  },

  getProductTariffListBegin: () => {
    return {
      type: actions.GET_PRODUCT_TARIFF_LIST_BEGIN,
    };
  },

  getProductTariffListSuccess: (data, page) => {
    return {
      type: actions.GET_PRODUCT_TARIFF_LIST_SUCCESS,
      data,
      page,
    };
  },

  getProductTariffListError: err => {
    return {
      type: actions.GET_PRODUCT_TARIFF_LIST_ERROR,
      err,
    };
  },

  getProductTariffDraftBegin: () => {
    return {
      type: actions.GET_PRODUCT_TARIFF_DRAFT_BEGIN,
    };
  },

  getProductTariffDraftSuccess: data => {
    return {
      type: actions.GET_PRODUCT_TARIFF_DRAFT_SUCCESS,
      data,
    };
  },

  getProductTariffDraftError: err => {
    return {
      type: actions.GET_PRODUCT_TARIFF_DRAFT_ERROR,
      err,
    };
  },

  getCheckProductTariffDraftBegin: () => {
    return {
      type: actions.GET_CHECK_PRODUCT_TARIFF_DRAFT_BEGIN,
    };
  },

  getCheckProductTariffDraftSuccess: data => {
    return {
      type: actions.GET_CHECK_PRODUCT_TARIFF_DRAFT_SUCCESS,
      data,
    };
  },

  getCheckProductTariffDraftError: err => {
    return {
      type: actions.GET_CHECK_PRODUCT_TARIFF_DRAFT_ERROR,
      err,
    };
  },

  getProductTariffMoveListBegin: () => {
    return {
      type: actions.GET_PRODUCT_TARIFF_MOVE_LIST_BEGIN,
    };
  },

  getProductTariffMoveListSuccess: data => {
    return {
      type: actions.GET_PRODUCT_TARIFF_MOVE_LIST_SUCCESS,
      data,
    };
  },

  getProductTariffMoveListError: err => {
    return {
      type: actions.GET_PRODUCT_TARIFF_MOVE_LIST_ERROR,
      err,
    };
  },

  getOneProductTariffBegin: () => {
    return {
      type: actions.GET_ONE_PRODUCT_TARIFF_BEGIN,
    };
  },

  getOneProductTariffSuccess: data => {
    return {
      type: actions.GET_ONE_PRODUCT_TARIFF_SUCCESS,
      data,
    };
  },

  getOneProductTariffError: err => {
    return {
      type: actions.GET_ONE_PRODUCT_TARIFF_ERROR,
      err,
    };
  },

  postProductTariffBegin: () => {
    return {
      type: actions.POST_PRODUCT_TARIFF_BEGIN,
    };
  },

  postProductTariffSuccess: data => {
    return {
      type: actions.POST_PRODUCT_TARIFF_SUCCESS,
      data,
    };
  },

  postProductTariffError: err => {
    return {
      type: actions.POST_PRODUCT_TARIFF_ERROR,
      err,
    };
  },

  patchProductTariffBegin: () => {
    return {
      type: actions.PATCH_PRODUCT_TARIFF_BEGIN,
    };
  },

  patchProductTariffSuccess: data => {
    return {
      type: actions.PATCH_PRODUCT_TARIFF_SUCCESS,
      data,
    };
  },

  patchProductTariffError: err => {
    return {
      type: actions.PATCH_PRODUCT_TARIFF_ERROR,
      err,
    };
  },

  patchProductTariffConfirmationBegin: () => {
    return {
      type: actions.PATCH_PRODUCT_TARIFF_CONFIRMATION_BEGIN,
    };
  },

  patchProductTariffConfirmationSuccess: data => {
    return {
      type: actions.PATCH_PRODUCT_TARIFF_CONFIRMATION_SUCCESS,
      data,
    };
  },

  patchProductTariffConfirmationError: err => {
    return {
      type: actions.PATCH_PRODUCT_TARIFF_CONFIRMATION_ERROR,
      err,
    };
  },

  deleteProductTariffBegin: () => {
    return {
      type: actions.DELETE_PRODUCT_TARIFF_BEGIN,
    };
  },

  deleteProductTariffSuccess: data => {
    return {
      type: actions.DELETE_PRODUCT_TARIFF_SUCCESS,
      data,
    };
  },

  deleteProductTariffError: err => {
    return {
      type: actions.DELETE_PRODUCT_TARIFF_ERROR,
      err,
    };
  },

  getMusavirListBegin: () => {
    return {
      type: actions.GET_MUSAVIR_LIST_BEGIN,
    };
  },

  getMusavirListSuccess: data => {
    return {
      type: actions.GET_MUSAVIR_LIST_SUCCESS,
      data,
    };
  },

  getMusavirListError: err => {
    return {
      type: actions.GET_MUSAVIR_LIST_ERROR,
      err,
    };
  },

  addBeyannameTaslakBegin: () => {
    return {
      type: actions.ADD_BEYANNAME_TASLAK_BEGIN,
    };
  },

  addBeyannameTaslakSuccess: data => {
    return {
      type: actions.ADD_BEYANNAME_TASLAK_SUCCESS,
      data,
    };
  },

  addBeyannameTaslakError: err => {
    return {
      type: actions.ADD_BEYANNAME_TASLAK_ERROR,
      err,
    };
  },

  getBeyannameTaslakBegin: () => {
    return {
      type: actions.GET_BEYANNAME_TASLAK_BEGIN,
    };
  },
  getBeyannameTaslakSuccess: data => {
    return {
      type: actions.GET_BEYANNAME_TASLAK_SUCCESS,
      data,
    };
  },

  getBeyannameTaslakError: err => {
    return {
      type: actions.GET_BEYANNAME_TASLAK_ERROR,
      err,
    };
  },

  getBeyannameListBegin: () => {
    return {
      type: actions.GET_BEYANNAME_LIST_BEGIN,
    };
  },
  getBeyannameListSuccess: data => {
    return {
      type: actions.GET_BEYANNAME_LIST_SUCCESS,
      data,
    };
  },

  getBeyannameListError: err => {
    return {
      type: actions.GET_BEYANNAME_LIST_ERROR,
      err,
    };
  },

  updateGGBMasterBegin: () => {
    return {
      type: actions.UPDATE_GGBMASTER_BEGIN,
    };
  },
  updateGGBMasterSuccess: data => {
    return {
      type: actions.UPDATE_GGBMASTER_SUCCESS,
      data,
    };
  },
  updateGGBMasterError: err => {
    return {
      type: actions.UPDATE_GGBMASTER_ERROR,
      err,
    };
  },

  updateGGBDetayBegin: () => {
    return {
      type: actions.UPDATE_GGBDETAY_BEGIN,
    };
  },
  updateGGBDetaySuccess: data => {
    return {
      type: actions.UPDATE_GGBDETAY_SUCCESS,
      data,
    };
  },
  updateGGBDetayError: err => {
    return {
      type: actions.UPDATE_GGBDETAY_ERROR,
      err,
    };
  },

  updateToplamBrutDagitBegin: () => {
    return {
      type: actions.UPDATE_TOPLAM_BRUT_DAGIT_BEGIN,
    };
  },
  updateToplamBrutDagitSuccess: data => {
    return {
      type: actions.UPDATE_TOPLAM_BRUT_DAGIT_SUCCESS,
      data,
    };
  },
  updateToplamBrutDagitError: err => {
    return {
      type: actions.UPDATE_TOPLAM_BRUT_DAGIT_ERROR,
      err,
    };
  },

  addDonusDepoBegin: () => {
    return {
      type: actions.ADD_DONUS_DEPO_BEGIN,
    };
  },
  addDonusDepoSuccess: data => {
    return {
      type: actions.ADD_DONUS_DEPO_SUCCESS,
      data,
    };
  },
  addDonusDepoError: err => {
    return {
      type: actions.ADD_DONUS_DEPO_ERROR,
      err,
    };
  },

  getOnaysizDekontListBegin: () => {
    return {
      type: actions.GET_ONAYSIZ_DEKONT_LIST_BEGIN,
    };
  },
  getOnaysizDekontListSuccess: data => {
    return {
      type: actions.GET_ONAYSIZ_DEKONT_LIST_SUCCESS,
      data,
    };
  },
  getOnaysizDekontListError: err => {
    return {
      type: actions.GET_ONAYSIZ_DEKONT_LIST_ERROR,
      err,
    };
  },

  updateDekontOnayBegin: () => {
    return {
      type: actions.UPDATE_DEKONT_ONAY_BEGIN,
    };
  },
  updateDekontOnaySuccess: data => {
    return {
      type: actions.UPDATE_DEKONT_ONAY_SUCCESS,
      data,
    };
  },
  updateDekontOnayError: err => {
    return {
      type: actions.UPDATE_DEKONT_ONAY_ERROR,
      err,
    };
  },

  dekontFirmaBilgiExcelBegin: () => {
    return {
      type: actions.DEKONT_FIRMA_BILGI_EXCEL_BEGIN,
    };
  },
  dekontFirmaBilgiExcelSuccess: data => {
    return {
      type: actions.DEKONT_FIRMA_BILGI_EXCEL_SUCCESS,
      data,
    };
  },
  dekontFirmaBilgiExcelError: err => {
    return {
      type: actions.DEKONT_FIRMA_BILGI_EXCEL_ERROR,
      err,
    };
  },

  dekontHataBildirBegin: () => {
    return {
      type: actions.DEKONT_HATA_BILDIR_BEGIN,
    };
  },
  dekontHataBildirSuccess: data => {
    return {
      type: actions.DEKONT_HATA_BILDIR_SUCCESS,
      data,
    };
  },
  dekontHataBildirError: err => {
    return {
      type: actions.DEKONT_HATA_BILDIR_ERROR,
      err,
    };
  },

  masrafTipProcessBegin: () => {
    return {
      type: actions.MASRAF_TIP_PROCESS_BEGIN,
    };
  },
  masrafTipProcessSuccess: data => {
    return {
      type: actions.MASRAF_TIP_PROCESS_SUCCESS,
      data,
    };
  },
  masrafTipProcessError: err => {
    return {
      type: actions.MASRAF_TIP_PROCESS_ERROR,
      err,
    };
  },
};

export default actions;
