const actions = {
  GET_DASHBOARDS_BEGIN: 'GET_DASHBOARDS_BEGIN',
  GET_DASHBOARDS_SUCCESS: 'GET_DASHBOARDS_SUCCESS',
  GET_DASHBOARDS_ERROR: 'GET_DASHBOARDS_ERROR',

  GET_WIDGET_DETAIL_BEGIN: 'GET_WIDGET_DETAIL_BEGIN',
  GET_WIDGET_DETAIL_SUCCESS: 'GET_WIDGET_DETAIL_SUCCESS',
  GET_WIDGET_DETAIL_ERROR: 'GET_WIDGET_DETAIL_ERROR',

  getDashboardsBegın: () => {
    return {
      type: actions.GET_DASHBOARDS_BEGIN,
    };
  },

  getDashboardsSuccess: data => {
    return {
      type: actions.GET_DASHBOARDS_SUCCESS,
      data,
    };
  },

  getDashboardsError: err => {
    return {
      type: actions.GET_DASHBOARDS_ERROR,
      err,
    };
  },

  getWidgetDetailBegin: () => {
    return {
      type: actions.GET_WIDGET_DETAIL_BEGIN,
    };
  },

  getWidgetDetailSuccess: data => {
    return {
      type: actions.GET_WIDGET_DETAIL_SUCCESS,
      data,
    };
  },

  getWidgetDetailError: err => {
    return {
      type: actions.GET_WIDGET_DETAIL_ERROR,
      err,
    };
  },
};

export default actions;
